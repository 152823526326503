var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news",on:{"click":_vm.close}},[_c('menuBox'),_vm._m(0),_c('div',{staticClass:"new-title"},[_c('div',{staticClass:"cn"},[_vm._v(_vm._s(_vm.titlecn))]),_c('div',{staticClass:"en"},[_vm._v(_vm._s(_vm.titleen))])]),_c('div',[_c('a-row',[_c('a-col',{staticClass:"listcol",attrs:{"xxl":17,"xl":17,"lg":17,"md":{
          span:17,
         
        },"xs":24}},[_c('div',{staticClass:"list"},[_vm._l((_vm.newsList),function(item){return _c('div',{key:item.id,staticClass:"item flex"},[_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"large"},[_vm._v(_vm._s(_vm.moment(item.sendTime).format('DD/')))]),_c('div',[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.moment(item.sendTime).format('MM月')))]),_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.moment(item.sendTime).format('YYYY')))])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"content-title"},[_vm._v(" "+_vm._s(item.newTitle)+" ")]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(item.newContent)}}),_c('div',{staticClass:"info",on:{"click":function($event){return _vm.toInfo(item.id)}}},[_vm._v(" 查看详情 ")])])])}),(_vm.newsList.length == 0)?_c('div',{staticClass:"noList"},[_c('img',{attrs:{"src":require("@/assets/noList.png"),"alt":""}}),_c('div',[_vm._v("暂无数据")])]):_vm._e(),_c('div',{staticClass:"bottom"},[_c('a-pagination',{attrs:{"show-quick-jumper":"","default-current":_vm.queryParams.pageNo,"total":_vm.total},on:{"change":_vm.onPaginationChange}})],1)],2)]),_c('a-col',{staticClass:"searchcol",attrs:{"xxl":{
          span:7,
        },"xl":7,"lg":7,"md":7,"xs":24}},[_c('div',{staticClass:"search-box"},[_c('div',{staticClass:"year"},[_vm._v("年份")]),_c('div',{staticClass:"calendar"},[_c('div',[_c('div',{staticClass:"select flex",on:{"click":function($event){$event.stopPropagation();return _vm.toggles.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.selyear)+"年")]),_c('span',{staticClass:"arrow"},[_vm._v("▲")])]),_c('div',{staticClass:"toggle",staticStyle:{"display":"none"},attrs:{"id":"toggles"}},_vm._l((_vm.yearList),function(year){return _c('div',{key:year,staticClass:"years",class:year==_vm.selyear?'active-years':'',on:{"click":function($event){return _vm.selectYear(year)}}},[_vm._v(_vm._s(year)+"年")])}),0)]),_c('div',{staticClass:"calendars flex"},_vm._l((_vm.monthList),function(m){return _c('div',{key:m,staticClass:"mouth",class:m==_vm.selmonth?'active-mouth':'',on:{"click":function($event){return _vm.selectMonth(m)}}},[_vm._v(_vm._s(m)+"月")])}),0)]),_c('div',{staticClass:"search flex"},[_c('a-input',{staticClass:"input",attrs:{"placeholder":"关键词","allow-clear":""},model:{value:(_vm.queryParams.newTitle),callback:function ($$v) {_vm.$set(_vm.queryParams, "newTitle", $$v)},expression:"queryParams.newTitle"}}),_c('a-button',{staticClass:"button",on:{"click":_vm.getList}},[_vm._v("搜索")])],1)])])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('h1',{staticClass:"en-title"},[_vm._v("NEWS CENTER")]),_c('h2',{staticClass:"title"},[_vm._v("新闻中心")]),_c('p',{staticClass:"lettle-title"},[_vm._v("我们为您传递更多的企业信息")]),_c('p',{staticClass:"lettle-title"},[_vm._v("使您更加全方位的了解您所关注行业内容")])])
}]

export { render, staticRenderFns }