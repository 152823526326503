import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuIdx:0
  },
  getters: {},
  mutations: {
    changeMenuIdx(state,newVal){
      state.menuIdx = newVal
    },
    changeSearchwords(state,newVal){
      state.searchwords = newVal
    },
  },
  actions: {},
  modules: {},
});
