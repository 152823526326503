<template>
  <div class="tgfwCon">
    <div class="slidebg"></div>
    <a-row>
      <a-col
        :xxl="{offset:3,span:20}"
        :xl="{offset:3,span:20}"
        :lg="{span:24}"
        :md="{span:24}"

        :sm="{span:24}"
        :xs="{span:24}"
      >
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon07.png"
            alt=""
          >-->
          <div class="en">MULTIPLE SOLUTIONS</div>
          <div class="cn">我们能为您提供什么服务</div>
        </div>
      </a-col>
    </a-row>
    <div class="tgfwBody">
      <a-row>
        <!--<a-col
          :xxl="9"
          :xl="12"
          :lg="12"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <img
            class="tgfwPic"
            src="@/assets/servicePic1.png"
            alt=""
          >

        </a-col>-->
        <a-col
            :xxl="{offset:3,span:18}"
            :xl="{offset:3,span:18}"
            :lg="24"
            :md="24"
            :sm="24"
            :xs="24"
        >
          <div>
            <div class="tgfwDesc">
              一体化解决方案—— 安全有效帮助企业降本增效
            </div>
          </div>
          <div class="tab1">
            <div class="tgfwTabHead">
              <div
                v-for="(item, index) in list1"
                :key="index"
                :class="idxList1 == index?'activeHead':''"
                class="tabHead"
                @click="clickList1(index)"
              >
                <div>{{item.title}}</div>
              </div>
            </div>
            <div class="tgfwTabCon">
              <div
                class="content"
                v-for="(item, index) in list1"
                :key="index"
                :class="idxList1 == index?'activeCon':''"
              >

                <div
                  v-if="!item.list[0].list"
                  class="tgfwTabConBox"
                >
                  <div
                    v-for="(subItem, index) in item.list"
                    :key="index+79"
                    class="contentName"
                  >
                    {{subItem.name}}
                  </div>
                </div>
                <div
                  class="secondTab"
                  v-else
                >
                  <div
                    v-for="(subItem, subindex) in item.list"
                    :key="subindex"
                    class="secondTabName"
                    :class="idxSecond==subindex?'activeSecHead':''"
                    @click="secondTabHead(subindex)"
                  >
                    {{subItem.title}}
                  </div>

                  <div
                    class="subTabContent"
                    v-for="(subItem, subConindex) in item.list"
                    :key="subConindex+99"
                    :class="idxSecond == subConindex?'activeSecCon':''"
                  >
                    <div
                      v-for="(itemChild,indexChild) in subItem.list"
                      :key="indexChild"
                    >
                      <p>{{itemChild.desc1}}</p>
                      <p>{{itemChild.desc2}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="dlwh">
            <div class="dlwhTit">
              <h3>代理维护产品</h3>
              <p>根据用户需要，通过IDC专业的维护人员为用户提供托管设备的日常维护工作</p>
            </div>
            <a-row :gutter="30" class="serviceBlock">
                <a-col
                  :xxl="{span:8}"
                  :xl="{span:8}"
                  :lg="{span:8}"
                  :md="{span:24}"
                  :sm="{span:24}"
                  class="serviceItem"
                  v-for="(item,index) in dlwhList"
                  :key="index"
                >
                    <div>
                      <img :src="item.icon" alt="">
                      <span>{{item.title}}</span>
                    </div>
                </a-col>
            </a-row>
          </div>

          <div class="dlwh">
            <div class="dlwhTit">
              <h3>增值业务产品</h3>
              <p></p>
            </div>
            <a-row :gutter="20" class="serviceBlock">
              <a-col
                      :xxl="{span:8}"
                      :xl="{span:8}"
                      :lg="{span:8}"
                      :md="{span:24}"
                      :sm="{span:24}"
                      class="serviceItem"
                      v-for="(item,index) in zzywList"
                      :key="index"
              >
                <div>
                  <img :src="item.icon" alt="">
                  <span>{{item.title}}</span>
                </div>
              </a-col>
            </a-row>

          </div>

          <div class="dlwh">
            <div class="dlwhTit">
              <h3>系统集成产品</h3>
              <p>提供专业技术队伍为用户定制各类网络系统建设方案，并提供方案实施和设备安装调测等服务</p>
            </div>
            <a-row :gutter="20" class="serviceBlock">
              <a-col
                      :xxl="{span:8}"
                      :xl="{span:8}"
                      :lg="{span:8}"
                      :md="{span:24}"
                      :sm="{span:24}"
                      class="serviceItem"
                      v-for="(item,index) in xtjcList"
                      :key="index"
              >
                <div>
                  <img :src="item.icon" alt="">
                  <span>{{item.title}}</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: "tgfwBox",
  data() {
    return {
      idxList1: 0,
      idxSecond: 0,
      list1: [
        {
          title: "基础服务",
          list: [
            { name: "主机托管" },
            { name: "带宽出租" },
            { name: "虚拟主机" },
            { name: "算力出租" },
            { name: "网络接入服务" },
          ],
        },
        {
          title: "增值服务",
          list: [
            { name: "设备检测" },
            { name: "系统集成" },
            { name: "远程维护" },
            { name: "安全防护" },
            { name: "代理维护" },
            { name: "算力云" },
          ],
        },
        {
          title: "增值服务",
          list: [
            {
              title: "机柜租赁",
              list: [
                {
                  desc1: "单机租赁 ：使用电信标准已建成机柜类型",
                  desc2:
                    "机房租赁：电信方按照客户要求建设数据机房，整体交付客户",
                },
              ],
            },
            {
              title: "合作运营",
              list: [
                {
                  desc1: "电信方投建土建部分，合作方投建配套",
                  desc2: "合作方负责土建及配套，电信方负责土地及能耗指标",
                },
              ],
            },
          ],
        },
      ],
      dlwhList: [
        {
          title: "常规巡检服务",
          icon: require("@/assets/tgfw-icon1.png"),
          list: [
            { name: "客户机房环境巡检" },
            { name: "主设备状态面板灯巡检" },
            { name: "接入辅助设备巡检" },
            { name: "物理链路巡检" },
          ],
        },
        {
          title: "现场操作服务",
          icon: require("@/assets/tgfw-icon2.png"),
          list: [
            { name: "客户设备线路插拔" },
            { name: "客户设备开关机（物理）" },
            { name: "客户接入辅助设备巡检" },
            { name: "建立远程连接（接入显示器）" },
          ],
        },
        {
          title: "个性化服务",
          icon: require("@/assets/tgfw-icon3.png"),
          list: [
            { name: "工位、库房、会议室租用服务" },
            { name: "住宿、餐饮等配套服务" },
            { name: "基于客户个性化的服务内容、流程、规范" },
          ],
        },
      ],

      xtjcList: [
        {
          title: "实施管理服务",
          icon: require("@/assets/tgfw-icon7.png"),
          list: [
            { name: "设备代收服务" },
            { name: "设备上下架服务" },
            { name: "综合布线、线缆熔接服务" },
            { name: "测试服务器租赁服务" },
          ],
        },
        {
          title: "系统高级操作",
          icon: require("@/assets/tgfw-icon8.png"),
          list: [
            { name: "系统安装、更新及升级" },
            { name: "数据库等软件安装、更新及升级" },
            { name: "设备故障处理，备品备件更换" },
          ],
        },
        {
          title: "应急响应及重保服务",
          icon: require("@/assets/tgfw-icon9.png"),
          list: [
            { name: "应急响应服务（网络响应）" },
            { name: "应急故障处理" },
            { name: "重大事件保障（现场值守）" },
          ],
        },
      ],

      zzywList: [
        {
          title: "天翼混合云一体机iStack",
          icon: require("@/assets/tgfw-icon4.png"),
          list: [
            {
              name: "采用x86/信创服务器，预集成天翼云平台，为客户提供计算、存储、网络、云管、运维全栈服务",
            },
            {
              name: "标准化快速交付，开箱即用、极简运维，承载边缘灵活上云需求",
            },
          ],
        },
        {
          title: "天翼云弹性裸金属",
          icon: require("@/assets/tgfw-icon5.png"),
          list: [
            {
              name: "采用自研软硬一体化架构，实现了网络和存储的卸载以及硬件加速",
            },
            {
              name: "兼具云服务器弹性和物理机性能的天翼云弹性裸金属服务器，为客户提供完整的解决方案",
            },
          ],
        },
        {
          title: "天翼云算力平台",
          icon: require("@/assets/tgfw-icon6.png"),
          list: [
            { name: "应用场景快速部署，提供公共镜像、私有镜像、共享镜像" },
            { name: "根据用户的业务需求，通过策略自动调整其弹性计算资源" },
          ],
        },
      ],
    };
  },
  methods: {
    clickList1(index) {
      this.idxList1 = index;
    },
    secondTabHead(index) {
      this.idxSecond = index;
    }
  },
};
</script>

<style scoped lang="scss">
.tgfwCon {
  padding-bottom: 40px;
  position: relative;
  .slidebg {
    width: 492px;
    height: 1186px;
    position: absolute;
    bottom: 0px;
    right: 0;
    background: url("@/assets/slidebg.png") no-repeat;
  }
  .tgfwBody {
    margin-top: 30px;

    .tgfwDesc {
      font-size: 26px;
      color: $primary-color;
      text-align: center;
      padding: 0 0 30px;
    }

    .tgfwPic {
      width: 100%;
      margin-top: -100px;
      vertical-align: top;
    }
  }
  .serviceBlock{
    .serviceItem{
      div{
        border: 1px solid #e2e6f1;
        background: #fff;
        border-radius: 5px;
        padding: 10px 0 15px;
      }
      img,span{
        display: block;
      }
      img{
        width:120px ;
        margin: 0 auto;
      }
      span{
        font-size: 18px;
        text-align: center;
        color: #5d6164;
        margin-bottom: 20px;
      }
    }
  }
  /*tab切换样式 start */
  /*头部*/
  .tgfwTabHead {
    background: #f0f4ff;
    display: flex;
    justify-content: left;
    .tabHead {
      width: 33.3%;
      display: inline-block;
      padding: 15px 40px;
      color: #606163;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      position: relative;
      cursor: pointer;
    }
    div.activeHead {
      background: #f9faff;
      color: $primary-color;
      &::after {
        content: "";
        border: 2px solid $primary-color;
        position: absolute;
        top: -3px;
        left: 0;
        width: 100%;
        z-index: 2;
      }
    }
  }
  .secondTab {
    background: #f9faff;
    .secondTabName {
      display: inline-block;
      padding: 10px 40px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }
    div.activeSecHead {
      color: $primary-color;
      background: #f9faff;
      box-shadow: 0 0 5px #e2e6f1;
    }
    .subTabContent {
      display: none;
    }
    div.activeSecCon {
      display: block;
    }
    .subTabContent {
      padding: 20px;
      p {
        margin: 0;
        padding: 0 15px;
        color: $primary-color;
        font-size: 16px;
        display: inline-block;
        position: relative;
        &::before {
          content: "";
          width: 7px;
          height: 7px;
          background: $primary-color;
          border-radius: 50%;
          position: absolute;
          left: 0px;
          top: 7px;
        }
      }
    }
  }

  .tgfwTabCon {
    border-left: 1px solid #e2e6f1;
    border-bottom: 1px solid #e2e6f1;
    border-right: 1px solid #e2e6f1;
    .tgfwTabConBox {
      padding: 20px;
    }
    .content {
      display: none;
    }

    div.activeCon {
      display: block;
    }
  }
  /*没有·的tabcontent*/
  .contentGrah {
    color: $primary-color;
    font-size: 16px;
    display: inline-block;
    padding: 5px 20px;
    position: relative;
       &::before {
      content: "";
      width: 7px;
      height: 7px;
      background: $primary-color;
      border-radius: 50%;
      position: absolute;
      left: 5px;
      top: 15px;
    }
  }
  /*有·的tabcontent*/
  .contentName {
    color: $primary-color;
    font-size: 16px;
    display: inline-block;
    padding: 6px 30px;
    position: relative;
    &::before {
      content: "";
      width: 7px;
      height: 7px;
      background: $primary-color;
      border-radius: 50%;
      position: absolute;
      left: 5px;
      top: 15px;
    }
  }
  .tab1 {
    background: #f9faff;
  }
  /*代理服务tab*/
  .dlwhTab {
    background: #f9faff;
    .dlwhTabHead {
    }
    .dlwhTabCon {
      display: none;
      border-left: 1px solid #e2e6f1;
      border-bottom: 1px solid #e2e6f1;
      border-right: 1px solid #e2e6f1;
      padding: 20px 20px;
      min-height: 113px;
    }
    div.activeContent {
      display: block;
    }
  }
  .dlwh {
    margin-bottom: 20px;
    .dlwhTit {
      display: block;
      text-align: center;
      padding: 40px 0 10px;
      h3 {
        font-family: youSheBiaoTi;
        font-size: 30px;
        color: $primary-color;
        margin: 0;
      }
      p {
        color: $font-color;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .tgfwCon {
    .tgfwBody {
      .tgfwTop {
        margin-top: 10px;
      }

      .tgfwPic {
        width: 90%;
        margin-top: -70px;
      }

      .tgfwContent {
        p {
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tgfwCon {
    .tgfwBody {
      .tgfwTop {
        margin-top: 10px;
      }

      .tgfwPic {
        width: 90%;
        margin-top: -20px;
      }

      .tgfwContent {
        p {
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .tgfwCon {
    .tgfwBody {
      .tgfwTop {
        margin-top: 10px;
      }

      .tgfwPic {
        width: 80%;
      }

      .tgfwDesc {
        display: inline-block;
        text-align: center;
      }

      .tgfwContent {
        p {
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .tgfwCon {
    .tabHead {
      font-size: 16px !important;
    }
    .secondTabName{
        font-size: 14px;
    }
    .tgfwBody {
      .tgfwTop {
        margin-top: 10px;
      }

      .tgfwBodyTop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
      }

      .tgfwPic {
        width: 70%;
      }

      .tgfwDesc {
        display: inline-block;
        text-align: center;
      }

      .tgfwContent {
        margin-bottom: 20px;

        li {
          width: 33%;
        }

        p {
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tgfwCon {
    .tabHead {
      font-size: 14px !important;
    }
    .secondTabName{
        font-size: 12px !important;
    }
    .tgfwBody {
      margin-top: 10px;

      .tgfwTop {
        margin-top: 10px;
      }

      .tgfwBodyTop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
      }

      .tgfwPic {
        width: 100%;
      }

      .tgfwDesc {
        display: inline-block;
        text-align: center;
        font-size: 16px;
      }

      .tgfwContent {
        margin-bottom: 20px;

        li {
          width: 33%;
          padding: 0;
        }

        p {
          margin: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .tgfwCon {
    padding: 0 15px;
  }
}
</style>
