import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import store from "./store";

import NProgress from 'nprogress' //引入自定义css是为了覆盖掉默认的进度条的颜色
import './assets/nprogress.css'
import axios from "axios";
/*import base64 from 'js-base64'

const Base64 = require('js-base64').Base64 // 挂载全局*/
Vue.config.productionTip = false;
window.onbeforeunload = function () {
  var n = window.event.screenX - window.screenLeft;
  var b = n > document.documentElement.scrollWidth - 20;
  if (b && window.event.clientY < 0 || window.event.altKey) {
    alert("是关闭而非刷新"); document.getElementById("ButtonClear").click();
    //window.event.returnValue = "";  
  } else {
    alert("是刷新而非关闭");
  }
}
Vue.prototype.axios = axios;

Vue.use(Antd);
// 引入utils.vue
import utils from './utils/utils'
// Vue.prototype注册的是一个全局的变量原型，而不是一个全局变量
// 注册全局变量原型utils
Vue.prototype.utils = utils

import 'babel-polyfill'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

NProgress.configure({
  easing: 'ease',  // 动画方式
  speed: 500,  // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  document.body.scrollTop = 0;

  // 每次切换页面时，调用进度条
  NProgress.start();
  // 对路由进行验证
  if (to.matched.some(m => m.meta.auth)) {  // 某路由需要登录权限
    const ifLogin = localStorage.getItem('isLogin')
    if (!ifLogin) { // 未登陆
      next({ path: '/login' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    next()
  }
});

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
});

(function (undefined) { }).call('object' === typeof window && window || 'object' === typeof self && self || 'object' === typeof global && global || {});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
