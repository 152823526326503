<template>
  <div class="ywzyCon">
    <!-- 修改 -->

    <a-row :gutter="20">
      <a-col :xxl="{offset:2,span:mapCol}"
             :xl="{offset:2,span:mapCol}"
             :lg="{offset:1,span:22}"
             :md="{offset:1,span:22}"
             :sm="{offset:0,span:24}"
             :xs="{offset:0,span:24}">
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon01.png"
            alt=""
          >-->
          <div class="en">CORE NODE</div>
          <div class="cn">东数西算北方核心节点之一</div>
        </div>
        <div class="mapCardImg"
             @mouseenter="mapMouseenter"
             v-if="showMap">
          <div>
            <img src="@/assets/map.png"
                 alt="">
          </div>
        </div>
        <div v-else
             class="card mapCardInfo"
             @mouseout="mapMouseout">
          <div class="mapCardInfoBg"></div>
          <span>中国电信内蒙古信息园区位于呼和浩特市和林格尔新区拥有良好的数字经
            济产业基础。园区作为东数西算北方核心节点之一，可以为京津冀地区高实时
            性算力需求提供服务，在链路上与北京互访时延5ms以内，确保园区接入企业
            可享受到同京津冀地区一样迅捷的网速服务和网络质量。可以为东中部地区，
            特别是长三角地区提供后台加工、离线分析、存储备份等非实时算力保障，
            助力东中部地区数字经济发展，建设“援京助东”北方重要算力中心。
          </span><br />
          <span class="card-center">
            <!--为东中部地区，特别是长三角国家枢纽节点提供后台加工、离线分析、存储备
            份等非实时算力保障，助力东中部地区数字经济发展，建设“援京助东”北方重
            要算力中心夯实了高效网络支撑。-->
          </span><br />
          中国电信内蒙古信息园区荣获荣誉：<br />
          *国家火炬大数据特色产业基地<br />
          *国家新型工业化（大数据）产业示范基地<br />
          *国家级互联网骨干直联点城市<br />
          *国际互联网数据专用通道<br />
          *全国一体化算力网络国家枢纽节点<br />
          *和林格尔新区荣获2020“中国最具投资营商环境新区（区域）”<br />
          *中国电信内蒙古信息园区荣获2021年“（区域）高质量发展最具投资价值园区”<br />
          *中国电信内蒙古信息园区荣获2021年度国家绿色数据中心称号<br />
        </div>
      </a-col>
      <a-col :xxl="{span:vrCol}"
             :xl="{span:vrCol}"
             :lg="{offset:0,span:22}"
             :md="{offset:1,span:22}"
             :sm="{span:24}"
             :xs="{span:24}">
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon02.png"
            alt=""
          >-->

          <div class="en">VR VISIT</div>
          <div class="cn">VR全景参观</div>
        </div>
        <div class="vrWarp"
             @mouseenter="vrMouseenter"
             @mouseout="vrMouseout">
          <div class="vrIcon"></div>
          <iframe src="https://nmgidc.com/140789/"></iframe>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "ywzyBox",
  data () {
    return {
      showMap: true,
      mapCol: 10,
      vrCol: 10,
    };
  },
  methods: {
    mapMouseenter () {
      this.showMap = false;
      this.mapCol = 12;
      this.vrCol = 8;
    },
    mapMouseout () {
      this.showMap = true;
      this.mapCol = 10;
      this.vrCol = 10;
    },
    vrMouseenter () {
      this.mapCol = 8;
      this.vrCol = 12;
    },
    vrMouseout () {
      this.mapCol = 10;
      this.vrCol = 10;
    },
  },
};
</script>

<style scoped lang="scss">
.ywzyCon {
  position: relative;
  z-index: 9;
  background: url("@/assets/map-bg.png") no-repeat center;
  padding-bottom: 70px;
  overflow: hidden;
  .card {
    cursor: pointer;
  }
  .mapCardInfo {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #677485;
    height: 550px;
    padding: 40px 60px 60px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    span {
      display: inline-block;
      text-indent: 32px;
    }
    span.card-center {
      padding: 10px 0;
    }
  }
  .mapCardInfoBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3) url("@/assets/map.png") no-repeat top
      center;
    background-size: 95%;
    opacity: 0.06;
  }
  .mapCardImg {
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    height: 550px;
    div {
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        height: 100%;
        width: 100%;
      }
      /*background: url("@/assets/map.png") no-repeat top center;
      background-size: 95%;*/
    }
  }

  .vrWarp {
    position: relative;
    height: 550px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 15px;
    }
    .vrIcon {
      width: 360px;
      height: 340px;
      position: absolute;
      right: -200px;
      top: -125px;
      background: url("@/assets/vr-icon.png") no-repeat;
      z-index: -1;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .ywzyCon {
    .mapCardInfo {
      height: 450px;
      font-size: 14px;
      padding: 20px 20px 0px;
    }
    .mapCardImg,
    .vrWarp {
      height: 450px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .ywzyCon {
    margin-top: -30px;
  }
}

@media screen and (max-width: 576px) {
  .ywzyCon {
    margin-top: 0px;
    .mapCardInfo {
      height: auto;
      font-size: 14px;
      padding: 14px 14px 0px;
    }
    .mapCardImg,
    .vrWarp {
      height: 350px;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .ywzyCon {
    padding: 0 15px;
  }
}
</style>
