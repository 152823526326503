<template>
  <div class="news">
    <menuBox></menuBox>
    <div class="top">
      <h1 class="en-title">NEWS CENTER</h1>
      <h2 class="title">新闻中心</h2>
      <p class="lettle-title">我们为您传递更多的企业信息</p>
      <p class="lettle-title">使您更加全方位的了解您所关注行业内容</p>
    </div>
    <div class="new-title">
      <div class="cn">{{info.newTitle}}</div>
      <a-row>
        <a-col
          :offset="3"
          :span="18"
        >
          <div class="time">
            {{info.newType_dictText}}&emsp;&emsp;{{moment(info.sendTime).format('YYYY-MM-DD')}}
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row>
        <a-col
          :offset="3"
          :span="18"
        >
          <div class="info">
            <p v-html="info.newContent"></p>
          </div>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
import menuBox from "@/components/menuBox.vue";
import moment from "moment";
import { windowLink, newsInfo } from "@/api/index.js";
export default {
  components: {
    menuBox,
  },
  data() {
    return {
      id: "",
      info: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.id = this.$route.query.id;
      this.getDetail();
    });
  },
  methods: {
    moment,
    getDetail() {
      newsInfo({ id: this.id }).then((res) => {
        if (res.success) {
          this.info = res.result;
        }
      });
    },
    paresImg(url) {
      return windowLink + url;
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  .top {
    padding: 64px;
    background: url("@/assets/new-bg.jpg") no-repeat;
    background-position: 70%;
    background-size: cover;
    height: 500px;
    .en-title {
      margin: 0;
      font-size: 60px;
      letter-spacing: 3px;
      padding-top: calc(8% - 64px);
      font-weight: bold;
      color: #0067fe;
    }
    .title {
      color: #0067fe;
      letter-spacing: 3px;
      font-weight: bold;
      font-size: 40px;
    }
    .lettle-title {
      color: #0067fe;
      letter-spacing: 2px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .new-title {
    color: #0067fe;
    padding-top: 80px;
    padding-bottom: 20px;
    position: relative;
  
    .cn {
      text-align: center;
      font-size: 32px;
    }
    .time {
      text-align: left;
      padding-top: 20px;
      color: #999;
    }
  }
    .info{
      padding: 20px 0;
    }
}
@media screen and (max-width: 1000px){
  .top {
    padding: 32px !important;
    height: 500px !important;
    .en-title{
      font-size: 40px !important;
    };
    .title{
      font-size: 32px !important;
    }
  }
  .new-title{
    .cn{
      font-size: 36px !important;
    }
    .en{
      font-size: 18px !important;
    }
  }
  .banner-box{
    padding: 16px !important;
    .banner-img {
      img{
        height: 250px !important;
      }
    }
    .banner-titl{
      font-size: 20px !important;
    }
    .banner-content{
      height: 150px !important;
    }
    .bottom{
      font-size: 14px !important;
    }
  }
} 
@media screen and (max-width: 650px) {
  .item{
    .left {
      flex: 120px 0 0 !important;
      .large{
        font-size: 32px !important;
      }
    }
  }
  .top {
    padding: 32px !important;
    height: auto !important;
    .en-title{
      font-size: 40px !important;
    };
    .title{
      font-size: 32px !important;
    }
  }
  
  .new-title{
    .cn{
      font-size: 28px !important;
    }
    .en{
      font-size: 18px !important;
    }
  }
  .banner-box{
    padding: 16px !important;
    .banner-img {
      img{
        height: 150px !important;
      }
    }
    .banner-titl{
      font-size: 20px !important;
    }
    .banner-content{
      height: 100px !important;
    }
    .bottom{
      font-size: 14px !important;
    }
  }
  .tab{
    padding: 0 !important;
    margin: 0 auto !important;
    div{
      padding: 16px !important;
    }
  }
}
@media screen and (max-width: 450px){
  .tab{
    padding: 0 !important;
    margin: 0 auto !important;
    div{
      padding: 8px !important;
    }
  }
} 
</style>