<template>
  <div class="zgygCon">
    <div class="cloud"></div>
    <div class="cloud0"></div>
    <a-row>
      <a-col
        :xxl="{offset:2,span:10}"
        :xl="{offset:2,span:10}"
        :lg="{offset:1,span:22}"
        :md="{offset:1,span:22}"
        :sm="{span:24}"
        :xs="{span:24}"
      >
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon03.png"
            alt=""
          >-->
          <div class="en">INTRODUCE</div>
          <div class="cn">中国云谷</div>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="20">
      <a-col
        :xxl="{offset:2,span:9}"
        :xl="{offset:2,span:10}"
        :lg="{offset:1,span:22}"
        :md="{offset:1,span:22}"
        :sm="{offset:0,span:24}"
        :xs="{offset:0,span:24}"
      >
        <div class="zgygLeft">
          <img
            src="@/assets/zgygPic.png"
            alt=""
          />
          <div class="zgygLeftInfo">
            <b>总体规模： </b><br />
            规划 1500亩的云计算园区，投资近200亿<br />
            容量10万机架，100万台服务器，42栋机楼和19栋配套楼
          </div>
        </div>
      </a-col>
      <a-col
        :xxl="{span:11}"
        :xl="{span:10}"
        :lg="{span:22}"
        :md="{span:22}"
        :sm="{span:24}"
        :xs="{span:24}"
      >
        <div class="zgygRight">
          <div class="zgygTab">
            <!--                        第一行tab切换-->
            <div class="zgygTabHead">
              <div
                class="zgygItem"
                v-for="(item,index) in list1"
                :key="index"
              >
                <div class="zgygItemName">{{item.name}}</div>
                <div class="zgygTabContainer">
                    <div class="zgygUnit">
                      <span
                              v-for="(unit,index) in item.list"
                              :key="index"
                      >{{unit.title}}</span>
                    </div>
                </div>
              </div>
            </div>


          </div>
          <ul class="zgygBottom">
            <li
              v-for="(item,index) in list3"
              :key="index"
            >
              <img
                :src="item.icon"
                alt=""
              >
              <p>{{item.title}}</p>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: "zgygBox",
  data() {
    return {
      idx: 0,
      idx2: 1,
      list1: [
        {
          name: "建筑",
          list: [
            { title: "8栋机楼 2栋动力楼" },
            // { title: "2栋动力楼" },
            { title: "1栋综合楼 1栋后保楼" },
            // { title: "1栋后保楼" },
            { title: "建筑面积18000㎡/栋" },
          ],
        },
        {
          name: "供电",
          list: [
            { title: "独享110KV变电站 74套柴油发电机" },
            { title: "2路独立市电+柴发 2NUPS/HVDC+蓄电池" },
            // { title: "2NUPS/HVDC+蓄电池" },
            // { title: "74套柴油发电机" },
            { title: "3*100m³油库 12h后备储油" },
            // { title: "12h后备储油" },
          ],
        },{
          name: "供水",
          list: [
            { title: "两路市政供水 2*1500m³蓄水池" },
            // { title: "2*1500m³蓄水池" },
            { title: "24h应急用水 园内环网" },
            // { title: "园内环网" },
          ],
        },


        {
          name: "后勤",
          list: [
            // { title: "四星级客房" },
            { title: "300人同时用餐 四星级客房" },
            // { title: "星级会议接待设施" },
            { title: "室内外运动场馆 星级会议接待设施" },
          ],
        },
        {
          name: "机柜",
          list: [
            { title: "2万架" },
            { title: "4.5-10kW" }
          ],
        },

        {
          name: "网络",
          list: [
            // { title: "国家级骨干网直连点" },
            { title: "中国电信骨干网核心节点 国家级骨干网直连点" },
            // { title: "互联网出口达到TB级别" },
            { title: "部署TB级别一干传输节点 互联网出口达到TB级别" },
          ],
        }
      ],
      list3: [
        {
          title: "一级耐火",
          icon: require("@/assets/zgyg/iconbottom1.png"),
        },
        {
          title: "一级防水",
          icon: require("@/assets/zgyg/iconbottom2.png"),
        },
        {
          title: "8级抗震",
          icon: require("@/assets/zgyg/iconbottom3.png"),
        },
        {
          title: "9级抗裂",
          icon: require("@/assets/zgyg/iconbottom4.png"),
        },
        {
          title: "抵御强烈风暴",
          icon: require("@/assets/zgyg/iconbottom5.png"),
        },
      ],
    };
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.zgygCon {
  padding-bottom: 50px;
  position: relative;
  .cloud0 {
    position: absolute;
    top: 0;
    right: 0;
    width: 281px;
    height: 208px;
    background: url("@/assets/zgyg-cloud0.png") no-repeat center;
    background-size: 100%;
    z-index: 8;
  }
  .cloud {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 280px;
    height: 145px;
    background: url("@/assets/zgyg-cloud.png") no-repeat center;
    background-size: 100%;
    z-index: 9;
  }
  .zgygLeft {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 505px;
      border-radius: 15px;
    }
    .zgygLeftInfo {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: $primary-color;
      background: rgba(255, 255, 255, 0.8);
      padding: 16px;
      b {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  .zgygRight {
    height: 512px;
    padding-left: 15px;
    .zgygTab {
      .zgygTabHead {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .zgygItem:nth-child(odd) {
          width: 43%;
        }
        .zgygItem:nth-child(even) {
          width: 57%;
        }
        .zgygItem {
          text-align: left;
          overflow: hidden;
          cursor: pointer;
          .zgygItemIcon {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 15px;
          }
          .zgygItemName {
            display: inline-block;
            font-size: 24px;
            color: $primary-color;
            font-weight: 700;
            margin-bottom: 5px;
            margin-top: 10px;
          }
        }
      }
      .zgygTabContainer {
          display: block;
          overflow: hidden;
          .zgygUnit {
            text-align: left;
            width: 100%;
            color: $primary-color;
            span {
              width: 100%;
              display: inline-block;
              margin-top: 6px;
              flex: 50% !important;
              box-sizing: border-box;
            }
          }

      }
    }
    .zgygBottom {
      margin-top: 10px;
      display: flex;
      align-items: baseline;
      li {
        text-align: left;
        display: inline-block;
        width: 20%;
        color: $primary-color;
        font-size: 18px;

        p {
          margin: 0;
          font-size: 14px;
          overflow: hidden;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .zgygCon {
    .cloud0 {
      width: 200px;
      height: 146px;
    }
    .cloud {
      width: 205px;
      height: 105px;
    }
    .zgygRight {
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .zgygCon {
    .cloud0 {
      width: 200px;
      height: 146px;
    }
    .cloud {
      width: 205px;
      height: 105px;
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .zgygCon {
    .cloud0 {
      width: 200px;
      height: 146px;
    }
    .cloud {
      width: 205px;
      height: 105px;
    }
  }
}
@media screen and (max-width: 576px) {
  .zgygCon {
    .cloud0 {
      width: 160px;
      height: 120px;
    }
    .cloud {
      width: 160px;
      height: 80px;
    }
    .zgygLeft {
      border-radius: 8px;
      img {
        height: 285px;
      }
      .zgygLeftInfo {
        padding: 8px;
        b {
          font-size: 16px;
        }
      }
    }
    .zgygRight {
      padding: 0;
      .zgygUnit {
        span {
          font-size: 12px;
          height: auto !important;
          line-height: 1.5 !important;
        }
      }
    }

    .zgygBottom {
      img {
        width: 80%;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 1200px) {
  .zgygCon {
    padding: 0 15px;
  }
}
</style>
