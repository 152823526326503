<template>
    <div class="contactBox">
        <div style="position: relative">
            <div
                    class="contactBtn"
                    @click="clickContact"
            ></div>
            <div
                    class="contactCon"
                    v-if="showConatct"
            ></div>
            <messageBox></messageBox>
        </div>
    </div>
</template>

<script>
    import messageBox from "./messageBox";

    export default {
        name: "contactBox",
        components: {messageBox},
        props: {
            showBox: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                showConatct: false,
                showMsg: false,
            };
        },
        watch: {
            showBox: {
                handler(val) {
                    console.log(val);
                    this.showConatct = val;
                },
                immediate: true

            }
        },
        mounted() {
            this.showConatct = this.showBox
        },
        methods: {
            clickContact() {
                this.showMsg = false;
                this.showConatct = !this.showConatct;
            },
            clickMessage() {
                this.showConatct = false;
                this.showMsg = !this.showMsg;
            },
        },
    };
</script>

<style scoped lang="scss">
    .contactBox {
        position: fixed;
        bottom: 180px;
        right: 15px;
        cursor: pointer;
        z-index: 998;
        .contactBtn {
            font-size: 18px;
            color: #fff;
            width: 67px;
            height: 67px;
            text-align: center;
            background: url("@/assets/sucai/1/lianxi.png") no-repeat;
            background-size: 100%;
        }
        .contactCon {
            position: absolute;
            right: 80px;
            bottom: 40px;
            height: 359px;
            width: 179px;
            background: url("@/assets/sucai/1/弹窗.png") no-repeat;
            background-size: 99%;
        }
    }
</style>
