// 导入axios
import axios from 'axios';
import signMd5Utils from '@/utils/signMd5Utils'

// 进行一些全局配置
// 公共路由(网络请求地址)
// axios.defaults.baseURL = 'http://182.42.156.92:8401/fk-system';
axios.defaults.baseURL = windowConfig.baseUrl;
// 请求响应超时时间
axios.defaults.timeout = 8000;
// 封装自己的get/post方法
export default {
    get: function(path = '', data = null) {
        return new Promise(function(resolve, reject) {
            axios({
                method:"GET",
                url:path,
                params:data,
                headers:{
                    'X-Access-Token': window.localStorage.getItem('token'),
                    'X-TIMESTAMP':signMd5Utils.getDateTimeToString(),
                    'X-Sign':signMd5Utils.getSign(path, data)
                }
            }).then(function(response) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    },
    getNoToken: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios({
                method:"GET",
                url:path,
                params:data,
            }).then(function(response) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    },
    postNoToken: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.post(path, data)
                .then(function(response) {
                    resolve(response.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    },
	post: function(path = '', data = {}) {
	    return new Promise(function(resolve, reject) {
	        axios({
	            method:"POST",
	            url:path,
	            data:data,
	            headers:{
	                'X-Access-Token': window.localStorage.getItem('token'),
	            }
	        }).then(function(response) {
	            resolve(response.data);
	        }).catch(function(error) {
	            reject(error);
	        });
	    });
	},
    put: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios({
                method:"PUT",
                url:path,
                data:data,
                headers:{
                    'X-Access-Token': window.localStorage.getItem('token'),
                }
            }).then(function(response) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    },
    delete: function(path = '', data) {
        return new Promise(function(resolve, reject) {
            axios({
                method:"delete",
                url:path,
                params: data,
                headers:{
                    'X-Access-Token': window.localStorage.getItem('token'),
                }
            }).then(function(response) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    },
    upload: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios({
                url: path,
                data: data,
                method:'post' ,
                headers: {
                    'Content-Type': 'multipart/form-data',  // 文件上传
                },
            }).then(function(response) {
                resolve(response.data);
            }).catch(function(error) {
                reject(error);
            });
        });
    },
    uploadAction(url,parameter){
        return axios({
            url: url,
            data: parameter,
            method:'post' ,
            headers: {
                'Content-Type': 'multipart/form-data',  // 文件上传
                'X-Access-Token': window.localStorage.getItem('token'),
            },
        })
    }
};

