<template>
  <div>
    <div
      class="magText"
      @click="clickOpen"
    ></div>
    <div
      class="msgBox"
      v-if="msgShow"
    >
      <div class="msgHead">
        <span>留言板</span>
        <a-icon
          type="line"
          style="color:rgba(255,255,255,1)"
          @click="clickClose"
        />
      </div>
      <div class="msgCon">
        <a-form-model
          :model="formInline"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <a-form-model-item>
            <a-textarea
              v-model="formInline.content"
              placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"
            ></a-textarea>
          </a-form-model-item>
          <a-form-model-item>
            <a-input
              v-model="formInline.name"
              placeholder="姓名"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color:rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-input
              v-model="formInline.tel"
              type="phone"
              placeholder="电话（必填）"
            >
              <a-icon
                slot="prefix"
                type="phone"
                style="color:rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-input
              v-model="formInline.email"
              type="mail"
              placeholder="邮箱"
            >
              <a-icon
                slot="prefix"
                type="mail"
                style="color:rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-input
              v-model="formInline.address"
              type="address"
              placeholder="地址"
            >
              <a-icon
                slot="prefix"
                type="environment"
                style="color:rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              html-type="submit"
              class="submitBtn"
              :disabled="formInline.name === '' || formInline.tel === ''"
            >
              提交
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>

</template>

<script>
import { liuyanAdd } from "@/api/index";
export default {
  name: "messageBox",
  props: ["showMsg"],
  data() {
    return {
      msgShow: false,
      formInline: {
        content: "",
        name: "",
        tel: "",
        email: "",
        address: "",
      },
    };
  },
  methods: {
    clickOpen() {
      this.msgShow = !this.msgShow;
    },
    clickClose() {
      this.msgShow = false;
    },
    handleSubmit(e) {
      liuyanAdd(this.formInline).then((res) => {
        if (res.success) {
          this.$message.success("留言成功！我们随后与您联系！");
          this.msgShow = false;
        }
      });
      this.formInline.content = "";
      this.formInline.name = "";
      this.formInline.tel = "";
      this.formInline.email = "";
      this.formInline.address = "";
    },
  },
};
</script>

<style scoped lang="scss">
.magText {
  position: fixed;
  bottom: 90px;
  right: 15px;
  width: 67px;
  height: 67px;
  z-index: 99;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: url("@/assets/sucai/1/liuyan.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
}
.msgBox {
  position: fixed;
  bottom: 120px;
  right: 95px;
  background: #fff;
  box-shadow: 0 0 12px #999;
  border-radius: 6px;
  overflow: hidden;
  z-index: 99;
  .msgHead {
    padding: 15px 10px;
    background-image: linear-gradient(to right, #6c93fc, #6c93fc);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #fff;
    }
  }
  .msgCon {
    text-align: right;
    padding: 10px;
    .submitBtn {
      font-size: 18px;
      color: #fff;
      background-image: linear-gradient(to right, #6c93fc, #3a77f5);
    }
  }
}
</style>
<style>
.msgCon .ant-form-item {
  margin: 0 0 0 0;
}
</style>
