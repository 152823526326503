<template>
  <div class="business">
      <a-row :gutter="30">
        <a-col
          :xxl="{offset:3,span:10}"
          :xl="{offset:3,span:10}"
          :lg="{offset:1,span:22}"
          :md="{offset:1,span:22}"
          :sm="{span:24}"
          :xs="{span:24}"
        >
          <div class="titleWarp">
           <!-- <img
              src="@/assets/title-icon06.png"
              alt=""
            >-->
            <div class="en">CLOUD COMPUTING</div>
            <div class="cn">中国电信云计算内蒙古信息园区</div>
          </div>
        </a-col>
      </a-row>
    <a-row
      class="box"
      :gutter="30"
    >
      <a-col
        v-for="(item,i) in businessList"
        class="boxs"
        :key="i"
        :xxl="{span:5,offset:item.xxl||0}"
        :xl="{span:8,offset:item.xl||0}"
        :lg="{span:6,offset:item.lg||0}"
        :md="{span:8,offset:item.md||0}"
        :sm="{span:22,offset:item.sm||0}"
      >
        <div class="card">

          <div class="title">
            <h3>{{item.title}}</h3>
            <span>{{item.erTitle}}</span>
          </div>
          <div class="list">
            <div class="flex item">
              <span class="label">时长</span>
              <span class="value">{{item.sc}}</span>
            </div>
            <div class="flex item">
              <span class="label">电流功率</span>
              <span class="value">{{item.gl}}</span>
            </div>
            <div class="flex item">
              <span class="label">机柜高度</span>
              <span class="value">{{item.gd}}</span>
            </div>
          </div>
          <div class="operation">
            <div class="tags">
              <span
                v-for="tag in item.tag"
                :key="tag.index"
              >{{tag}}</span>
            </div>
            <div class="price">
              <span>低至<i>{{item.price}}</i>元/月起</span>
              <a-popover
                v-model="item.show"
                title=""
                trigger="click"
              >
                <img
                  style="width:100px;height:100px"
                  class="ercode"
                  slot="content"
                  src="~@/assets/erCode.png"
                  alt=""
                >
                <span
                  class="btn"
                  @click="showErcode(i)"
                >售前咨询</span>
              </a-popover>
            </div>
          </div>
        </div>

      </a-col>
    </a-row>
    <a-row>
      <a-col
        :xxl="{span:21,offset:3}"
        :xl="{span:21,offset:3}"
        :lg="{span:21,offset:3}"
        :md="{span:21,offset:3}"
        :sm="{span:21,offset:3}"
        class="tips"
      >
        机柜可根据客户需求进行定制改造
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      businessList: [
        {
          xxl: 2,
          xl:4,
          md: 4,
          sm: 1,
          title: "20A机柜",
          erTitle: "自建云计算中心，位于呼和浩特算力枢纽节点",
          sc: "按年签约，按月付款",
          gl: "20A&5KW",
          gd: "48U整机柜",
          tag: ["A级机房", "7*24小时", "等保3级"],
          price: "3000",
          wx: "",
          show: false,
        },
        {
          sm: 1,
          title: "32A机柜",
          erTitle: "自建云计算中心，位于呼和浩特算力枢纽节点",
          sc: "按年签约，按月付款",
          gl: "32A&7KW",
          gd: "48U整机柜",
          tag: ["A级机房", "7*24小时", "等保3级"],
          price: "4200",
          wx: "",
          show: false,
        },
        {
          sm: 1,md:4,
          xl:4,
          title: "36A机柜",
          erTitle: "自建云计算中心，位于呼和浩特算力枢纽节点",
          sc: "按年签约，按月付款",
          gl: "36A&8KW",
          gd: "48U整机柜",
          tag: ["A级机房", "7*24小时", "等保3级"],
          price: "4800",
          wx: "",
          show: false,
        },
        {
          sm: 1,
          title: "45A机柜",
          erTitle: "自建云计算中心，位于呼和浩特算力枢纽节点",
          sc: "按年签约，按月付款",
          gl: "45A&10KW",
          gd: "48U整机柜",
          tag: ["A级机房", "7*24小时", "等保3级"],
          price: "6000",
          wx: "",
          show: false,
        },
      ],
    };
  },
  methods: {
    showErcode(index) {
      let obj = this.businessList[index];
      obj.show = true;
      this.$set(this.businessList, index, obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.boxs{
  margin-top: 20px;
}
.business {
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
  .top {
    .primaryTit {
      text-align: left !important;
    }
    .primaryEnTit {
      text-align: left !important;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 0;
  }
  .card {
    position: relative;
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    text-align: left;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 40px;
      width: 80px;
      height: 3px;
      background-color: $primary-color;
    }
    .title {
      padding-bottom: 10px;
      border-bottom: 2px dashed #ccc;
      h3 {
        padding-top: 10px;
        color: $primary-color;
        font-weight: 700;
        font-size: 22px;
      }
      span {
        color: #999;
        font-size: 16px;
      }
    }
    .list {
      padding: 20px 0;
      border-bottom: 2px dashed #ccc;
      .item {
        justify-content: space-between;
        text-align: left;
        padding: 5px 0;
        .label {
          /*color: #999;*/
          width: 40%;
        }
        .value {
          width: 60%;
        }
      }
    }
    .operation {
      .tags {
        padding-top: 10px;
        span {
          color: $primary-color;
          display: inline-block;
          padding: 5px 10px;
          background-color: #eee;
          margin-right: 6px;
        }
      }
      .price {
        padding-top: 10px;
        span {
          color: #999;
          font-size: 16px;
          display: flex;
          align-items: baseline;
          i {
            display: block;
            padding: 0 10px;
            font-style: normal;
            font-size: 28px;
            color: $primary-color;
            font-weight: 600;
          }
        }
        .btn {
          background-color: $primary-color;
          display: flex;
          font-size: 14px;
          color: #fff;
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tips {
    padding-top: 20px;
    text-align: left;
    font-size: 16px;
    color: $primary-color;
    letter-spacing: 4px;
  }
}
@media screen and (min-width: 1200px) {
  .business {
    .card {
      .operation {
        .tags {
          display: flex;
          flex-wrap: wrap;
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .business {
    .card {
      position: relative;
      padding: 10px 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      text-align: left;
      .operation {
        .tags {
          span {
            color: $primary-color;
            /*display: block;*/
            padding: 5px 10px;
            margin-bottom: 2px;
            background-color: #eee;
            margin-right: 10px;
          }
        }
        .price {
          .btn {
            display: block;
            text-align: center;
          }
        }
      }
    }
    .tips {
      text-align: left;
    }
  }
}

@media screen and (max-width: 576px) {
  .business {
    padding: 0 15px;
    .card{
      margin-bottom: 15px;
    }
  }
}
.flex {
  display: flex;
}
</style>
