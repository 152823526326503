<template>
  <div class="sycjCon">
    <a-row>
      <a-col
              :xxl="{offset:2,span:10}"
              :xl="{offset:2,span:10}"
              :lg="{span:24}"
              :md="{span:24}"
              :sm="{span:24}"
              :xs="{span:24}"
      >
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon04.png"
            alt=""
          >-->
          <div class="en">WHY CHOOSE US</div>
          <div class="cn">为什么选择中国电信云计算内蒙古信息园</div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col
              :xxl="{offset:3,span:18}"
              :xl="{offset:3,span:18}"
              :lg="24"
              :md="24"
              :sm="24"
              :xs="24"
      >
        <div class="sycjBg">
          <ul class="sycjBody">
            <li
                    v-for="(item,index) in chooseList"
                    :key="index"
            >
              <div class="sycjItem">
                <div class="sycjItemTop">
                  <div class="img-box">
                    <img
                            :src="item.iconactive"
                            alt=""
                    >

                  </div>
                  <div>{{item.title}}</div>
                </div>
                <div class="sycjItemBottom">{{item.content}}</div>
              </div>
              <!-- @click="clicksycjLi(item,index)" -->
              <!-- <div
                class="sycjItem"
                @mouseenter="clicksycjLi(item,index)"
              >
                <div class="sycjItemTop">
                  <img
                    :src="item.icon"
                    alt=""
                  >
                  <div>{{item.title}}</div>
                </div>
                <div class="sycjItemBottom">{{item.content}}</div>
              </div>
              <div
                @mouseleave="outsycjLi"
                class="sycjItemHover"
                :class="'sycjItemHover'+index"
              >
                <div class="sycjItemTop">
                  <img
                    :src="item.iconactive"
                    alt=""
                  >
                  <div>{{item.title}}</div>
                </div>
                <div class="sycjItemBottom">{{item.content}}</div>
              </div> -->
            </li>
          </ul>
        </div>

      </a-col>
      <a-col
              :xxl="3"
              :xl="1"
              :lg="0"
              :md="0"
              :sm="0"
              :xs="0"
      ></a-col>
    </a-row>
    <!--  -->
  </div>
</template>

<script>
  export default {
    name: "sycjBox",
    data() {
      return {
        idx: 11,
        chooseList: [
          {
            icon: require("@/assets/sycj/icon01.png"),
            iconactive: require("@/assets/sycj/icon01-active.png"),
            title: "金融",
            content: "数据合规、业务连续、等保安全",
          },
          {
            icon: require("@/assets/sycj/icon02.png"),
            iconactive: require("@/assets/sycj/icon02-active.png"),
            title: "政府",
            content: "深度定制、安全稳定、互联互通",
          },
          {
            icon: require("@/assets/sycj/icon03.png"),
            iconactive: require("@/assets/sycj/icon03-active.png"),
            title: "电子商务",
            content: "扩容方便、高速网络、优质运维",
          },
          {
            icon: require("@/assets/sycj/icon04.png"),
            iconactive: require("@/assets/sycj/icon04-active.png"),
            title: "在线教育",
            content: "扩容方便、实时网络、CDN加速",
          },
          {
            icon: require("@/assets/sycj/icon05.png"),
            iconactive: require("@/assets/sycj/icon05-active.png"),
            title: "互联网",
            content: "高可靠性、超大规模、优质运维",
          },
          {
            icon: require("@/assets/sycj/icon06.png"),
            iconactive: require("@/assets/sycj/icon06-active.png"),
            title: "视频直播",
            content: "资源充足、超低时延、CDN加速",
          },
          {
            icon: require("@/assets/sycj/icon07.png"),
            iconactive: require("@/assets/sycj/icon07-active.png"),
            title: "工业",
            content: "扩容方便、优质运维、CDN加速",
          },
          {
            icon: require("@/assets/sycj/icon08.png"),
            iconactive: require("@/assets/sycj/icon08-active.png"),
            title: "卫健行业",
            content: "资源充足、超大存储、扩容方便",
          },
          {
            icon: require("@/assets/sycj/icon09.png"),
            iconactive: require("@/assets/sycj/icon09-active.png"),
            title: "交通物流",
            content: "海量存储、优质运维、高速网络",
          },
        ],
      };
    },
    methods: {
      clicksycjLi(item, index) {
        this.idx = index;
      },
      outsycjLi() {
        this.idx = 11;
      },
    },
  };
</script>

<style scoped lang="scss">
  .sycjCon {
    padding-top: 80px;
    padding-bottom: 100px;
    background: url(@/assets/sycj/bg.png) no-repeat center;
    background-size: 100% 100%;
    z-index: 9;
    .sycjBg {
      border: 2px solid $primary-color;
      border-radius: 25px;
      position: relative;
      overflow: hidden;
    }
    .sycjBody {
      z-index: 998;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 50px;
      &:after {
        z-index: -1;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(255, 255, 255, 0.52);
        left: 0;
        background-attachment: fixed;
        filter: blur(100px);
      }
      li {
        width: 31%;
        margin: 1% 1%;
        box-sizing: border-box;
        text-align: center;
        background: #f5f9fc;
        position: relative;
        display: inline-block;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 8%);
        overflow: hidden;
        &:hover {
          box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, 18%);
        }
        .sycjItemTop {
          display: flex;
          justify-content: start;
          align-items: center;
          color: $primary-color;
          font-weight: 600;
          font-size: 20px;
          padding-bottom: 20px;
          .img-box {
            width: 48px;
            height: 48px;
            background-color: $primary-color;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img {
              width: 80%;
              height: 80%;
            }
          }
          // div {
          // }
        }
        .sycjItemBottom {
          color: $font-color;
          font-size: 16px;
          text-align: left;
        }
        .sycjItem {
          opacity: 1;
          transition: all 0.25s ease-in-out;
          padding: 40px;
        }

        // .sycjItemHover {
        //   background-size: cover !important;
        //   height: 100%;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   -webkit-transform: translateY(280px);
        //   -ms-transform: translateY(280px);
        //   transform: translateY(280px);
        //   transition: -webkit-transform 0.4s ease;
        //   transition: transform 0.4s ease;
        //   box-sizing: border-box;
        //   text-align: center;
        //   padding: 56px 0;
        //   .sycjItemTop {
        //     color: #fff;
        //   }
        //   .sycjItemBottom {
        //     color: #fff;
        //   }
        // }
        // .sycjItemHover0 {
        //   background: url("@/assets/sycj/icon01-activebg.png") no-repeat center;
        // }
        // .sycjItemHover1 {
        //   background: url("@/assets/sycj/icon02-activebg.png") no-repeat center;
        // }
        // .sycjItemHover2 {
        //   background: url("@/assets/sycj/icon03-activebg.png") no-repeat center;
        // }
        // .sycjItemHover3 {
        //   background: url("@/assets/sycj/icon04-activebg.png") no-repeat center;
        // }
        // .sycjItemHover4 {
        //   background: url("@/assets/sycj/icon05-activebg.png") no-repeat center;
        // }
        // .sycjItemHover5 {
        //   background: url("@/assets/sycj/icon06-activebg.png") no-repeat center;
        // }
        // .sycjItemHover6 {
        //   background: url("@/assets/sycj/icon07-activebg.png") no-repeat center;
        // }
        // .sycjItemHover7 {
        //   background: url("@/assets/sycj/icon08-activebg.png") no-repeat center;
        // }
        // .sycjItemHover8 {
        //   background: url("@/assets/sycj/icon09-activebg.png") no-repeat center;
        // }
      }

      // .activeItem {
      //   box-shadow: 0 2px 16px 0 rgb(108 147 252 / 20%);

      //   .sycjItem {
      //     display: none;
      //   }

      //   .sycjItemHover {
      //     -webkit-transform: translateY(0px);
      //     -ms-transform: translateY(0px);
      //     transform: translateY(0px);
      //     z-index: 1;
      //   }
      // }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sycjCon {
      .sycjBody {
        li {
          .sycjItem {
            padding: 40px;
          }
          .sycjItemHover {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .sycjCon {
      .sycjBody {
        li {
          .sycjItem {
            img {
            }

            div {
            }
          }

          .sycjItemHover {
            padding: 56px 0;

            p {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .sycjCon {
      .sycjBody {
        li {
          .sycjItem {
            img {
            }

            div {
            }
          }

          .sycjItemHover {
            padding: 20px 0;

            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sycjCon {
      .sycjBody {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        padding: 10px;
        li {
          width: 48%;
          /*height: 180px;*/
          height: unset;
          margin: 1%;
          img {
            width: 50px;
          }
          .sycjItem {
            transition: unset;
            text-align: left;
            overflow: hidden;
            justify-content: left;
            align-items: center;

            div {
              color: $primary-color;
              font-size: 16px;
              font-weight: 600;
              line-height: 1;
              margin-top: 8px;
              margin-left: 8px;
            }
          }
          .sycjItemTop {
            display: flex;
            align-items: center;
            padding: 0;
            font-size: 18px;
            div {
              margin-left: 0;
            }
          }
          // .sycjItemHover {
          //   margin: 5px;
          //   position: unset;
          //   -webkit-transform: translateY(0px);
          //   -ms-transform: translateY(0px);
          //   transform: translateY(0px);
          //   transition: -webkit-transform 0.4s ease;
          //   transition: transform 0.4s ease;
          //   background-color: transparent;

          //   z-index: 1;
          //   height: auto !important;
          //   padding: 10px !important;

          //   p {
          //     font-size: 14px;
          //     color: $font-color;
          //   }
          // }
        }

        .activeItem {
          box-shadow: 0 2px 16px 0 rgb(108 147 252 / 20%);

          .sycjItem {
            display: flex;
            justify-content: left;
          }

          .sycjItemHover {
            -webkit-transform: translateY(0px);
            -ms-transform: translateY(0px);
            transform: translateY(0px);
          }
        }
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1200px) {
    .sycjCon {
      padding: 0 15px;
    }
  }
</style>
