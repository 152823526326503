<template>
  <div class="featured">
    <a-row>
      <a-col
              :xxl="{offset:3,span:18}"
              :xl="{offset:3,span:18}"
              :lg="{offset:3,span:18}"
              :md="24"
              :sm="24"
              :xs="24"
      >
        <div class="titleWarp">
<!--          <img src="@/assets/title-icon08.png" alt="">-->
          <div class="en">FEATURED BRANDS</div>
          <div class="cn">特色服务品牌</div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col
              :xxl="{offset:4,span:16}"
              :xl="{offset:3,span:18}"
              :lg="{offset:3,span:18}"
              :md="24"
              :sm="24"
              :xs="24"
      >
        <div class="qcxy">
          <img src="@/assets/qcxy.png" alt="">
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .featured{
    padding-top: 20px;
    padding-bottom: 40px;
    background: url(@/assets/tsfw-bg.png) no-repeat center;
    background-size: 100% 100%;
    .qcxy{
      padding: 40px;
      img{
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 576px) {
      .featured {
          padding: 0 15px;
          .qcxy{
              padding: 0 10px 20px;
          }
      }
  }
</style>
