<template>
  <div class="customer">
    <a-row>
      <a-col :xxl="{offset:3,span:18}"
             :xl="{offset:2,span:20}"
             :lg="{offset:1,span:22}"
             :md="{offset:1,span:22}"
             :sm="24"
             :xs="24">
        <div class="titleWarp">
          <!--<img
            src="@/assets/title-icon10.png"
            alt=""
          >-->
          <div class="en">CUSTOMER CASE</div>
          <div class="cn">我们的合作伙伴</div>
        </div>
      </a-col>
    </a-row>
    <a-row class="customer-box">
      <a-col :xxl="{offset:3,span:18}"
             :xl="{offset:2,span:20}"
             :lg="{offset:1,span:22}"
             :md="{offset:1,span:22}"
             :sm="24"
             :xs="24">
        <div class="list">
          <div class="item"
               v-for="(item,index) in customerList"
               :key="index"
               :class="idx==index?'activeCustom':''"
               @click="clickCustom(index)">
            <img :src="item.icon"
                 alt="">
            <p>{{item.title}}</p>
          </div>
        </div>
      </a-col>
    </a-row>
    <div style="display: flex;justify-content: center;padding: 40px 0;"><a href="https://beian.miit.gov.cn"
         style="margin:0  40px;">京ICP备16008444号-9</a>
      <span style="margin:0  40px;">访问人数：<i style="font-style: normal;">{{ visitNum }}</i></span>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      idx: 0,
      customerList: [
        {
          title: "云计算行业",
          icon: require("@/assets/customer-icon1.png"),
        },
        {
          title: "金融行业",
          icon: require("@/assets/customer-icon2.png"),
        },
        {
          title: "住建行业",
          icon: require("@/assets/customer-icon3.png"),
        },
        {
          title: "教育行业",
          icon: require("@/assets/customer-icon4.png"),
        },
        {
          title: "执法公安",
          icon: require("@/assets/customer-icon5.png"),
        },
        {
          title: "互联网行业",
          icon: require("@/assets/customer-icon6.png"),
        },
        {
          title: "工业行业",
          icon: require("@/assets/customer-icon7.png"),
        },
        {
          title: "交通物流",
          icon: require("@/assets/customer-icon8.png"),
        },
        {
          title: "卫健行业",
          icon: require("@/assets/customer-icon9.png"),
        },
        {
          title: "应急行业",
          icon: require("@/assets/customer-icon10.png"),
        },
      ],
    };
  },
  props: {
    visitNum: {
      type: Number,
      default: 0
    }
  },
  methods: {
    clickCustom (index) {
      this.idx = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.customer {
  padding-top: 20px;
  padding-bottom: 80px;
  background: url(@/assets/hzhb-bg.png) no-repeat center;
  background-size: 100% 100%;
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    position: relative;
    border: 5px solid;
    border-image: linear-gradient(to right, #fff, #578aef) 1;
    border-radius: 12px !important;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      // background: url("@/assets/yyrz-gsbj.png") no-repeat center;
      background-color: rgba($color: #fff, $alpha: 0.6);
      background-attachment: fixed;
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(10px);
      z-index: 1;
    }
    background-size: auto;
    .activeCustom {
      background: rgba(255, 255, 255, 0.6);
      border-radius: 15px;
    }
    .item {
      z-index: 2;
      width: 20%;
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      padding: 30px 0;
      text-align: center;
      p {
        padding-top: 20px;
      }
      img {
        display: block;
        margin: 0 auto;
        width: 70%;
        height: auto;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: -webkit-transform 0.4s ease;
        transition: transform 0.4s ease;
      }
      &:hover {
        img {
          -webkit-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transform: scale(1.3);
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .customer {
    padding: 10%;
    .list {
      padding: 20px;
    }
  }

  .item {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .customer {
    padding: 0 15px 30px 15px;
    .list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .item {
        width: 50%;
        border: 1px solid #eee;
        font-size: 14px;
        font-weight: 600;
        padding: 15px 0;

        p {
          line-height: 30px;
        }

        img {
          display: block;
          margin: 0 auto;
          margin-top: 5px;
          width: 40%;
          height: auto;
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
          transition: -webkit-transform 0.4s ease;
          transition: transform 0.4s ease;
        }
      }
    }
  }
}
</style>
