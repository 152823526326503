import VueRouter from "vue-router";
export default {
    // 方法1
    navigateArticlePage: function (article) {
        let routeData = this.$router.resolve({ path: '/articlePage',
            query: {
                articled: article.id,
            } });
        window.open(routeData.href, '_blank');
    },
    // 方法2
    mainTitleClick: function (title,code) {
        let routeData = this.$router.push({ path: '/SecondaryPage' });
        window.open(routeData.href, '_blank');
    }
}
