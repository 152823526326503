<template>
  <div
    class="menu"
    @click.stop="visible = false"
  >
    <a-icon
      class="icon"
      type="menu"
      v-if="moblieSreen &&!visible"
      @click.stop="showDrawer"
    />

    <!--        <img class="logo" src="@/assets/logo.png" alt="" @click="clickMobileMenu">-->
    <a-row v-if="!moblieSreen">
      <a-col
        :xxl="{offset:3,span:18}"
        :xl="{offset:1,span:22}"
        :lg="24"
        :md="24"
        :sm="24"
      >
        <div class="menus">
          <ul>
            <li
              class="menuItem"
              v-for="(menu,index) in menuList"
              :key="menu.id"
              @click="menuClick(menu,index)"
              :class="index==menuIdx?'activeMenu':''"
            >
              <a v-if="menu.name !=''">{{menu.title}}</a>
              <span v-else>{{menu.title}}</span>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
    <div
      class="drawerMenu-box"
      :class="visible?'boxright':'boxleft'"
      v-if="moblieSreen"
    >
      <ul class="drawerMenu">
        <a-icon
          class="icon"
          type="close"
          v-if="moblieSreen"
          @click.stop="showDrawer"
        />
        <li
          class="drawerMenuItem"
          v-for="(menu,index) in menuList"
          :key="menu.id"
          @click.stop="menuClick(menu,index)"
          :class="index==menuIdx?'activeMenu':''"
        >
          <a v-if="menu.name !=''">{{menu.title}}</a>
          <span v-else>{{menu.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "menuBox",

  data() {
    return {
      menuList: [
        {
          title: "首页",
          name: "sy",
          id: "1232",
          link: "",
        },
        {
          title: "东数西算",
          name: "jccpPage",
          id: "1233",
          link: "",
        },
        {
          title: "中国云谷",
          name: "zgyg",
          id: "1234",
          link: "",
        },
        {
          title: "方案",
          name: "sycjBox",
          id: "1236",
          link: "",
        },
        {
          title: "产品",
          name: "dlwhPage",
          id: "1235",
          link: "",
        },
        {
          title: "服务",
          name: "tgfwBox",
          id: "1237",
          link: "",
        },
        {
          title: "优势",
          name: "ys",
          id: "1238",
          link: "",
        },
        {
          title: "新闻",
          name: "",
          id: "1239",
          link: "news",
        },
      ],
      windowWidth: document.documentElement.clientWidth,
      moblieSreen: false,
      visible: false,
    };
  },
  watch: {
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val);
    },
  },

  computed: {
    menuIdx: {
      get() {
        return this.$store.state.menuIdx;
      },
      set(val) {
        this.$store.commit("changeMenuIdx", val);
      },
    },
  },
  created() {
    // alert(document.documentElement.clientWidth  )
    if (this.windowWidth < 660) {
      this.moblieSreen = true;
    }
  },
  methods: {
    clickMobileMenu() {},
    // 导航点击事件
    menuClick(menu, index) {
      sessionStorage.setItem("menu", JSON.stringify(menu));
      this.menuIdx = index;
      if (menu.link == "news") {
        this.$router.push("news");
        return;
      } else {
        this.$router.push("/");
        this.$nextTick(() => {
          this.hrefMD();
        });
      }
    },
    hrefMD() {
      let menu = JSON.parse(sessionStorage.getItem("menu"));
      document.querySelector(`#${menu.name}`).scrollIntoView(true);
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = !this.visible;
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  background: rgba($color: #fff, $alpha: 0.7);
  padding: 16px;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  z-index: 10;
  .mobileMenu {
    float: left;
    width: 40px;
  }
  .logo {
    width: 150px;
    margin: 10px 0;
    float: left;
  }
  .menus {
    ul {
      float: left;
      padding: 0;
      margin-left: 0px;
      overflow: hidden;
      li {
        float: left;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        margin: 0 15px;
        list-style: none;
        text-align: center;
        cursor: pointer;
        color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 5px;
        &:hover {
          color: #fff;
        }
        a,
        span {
          color: $primary-color;
          display: block;
          width: 100%;
          height: 100%;
          padding: 0 15px;
        }
      }
      li.activeMenu {
        color: #fff;
        background-color: $primary-color;
        background-size: 60%;
        a {
          color: #fff;
        }
        span {
          color: #fff;
        }
      }
    }
  }
}
.drawerMenu {
  z-index: 999;
  height: 100vh;
  padding: 16px;
  .drawerMenuItem {
    line-height: 40px;
    font-size: 18px;
  }
  .activeMenu {
    color: #6c93fc;
    font-weight: 700;
    background-size: 60%;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .menu {
    ul {
      li {
        margin: 6px !important;
        font-size: 20px !important;
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 10px !important;
      }
    }
    .mobileMenu {
      float: left;
      width: 40px;
    }
  }
}
@media screen and (max-width: 660px) {
  .menu {
    top: -5px;
    padding: 0 !important;
    .icon {
      margin: 16px;
    }
    .mobileMenu {
      float: left;
      width: 35px;
    }
    .logo {
      width: 100px;
      margin: 5px 10px;
      float: left;
    }
  }
  .drawerMenu-box {
    position: absolute;
    width: 50%;
  }
  .boxright {
    left: 0;
    animation-name: right;
    animation-duration: 1s;
  }
  .boxleft {
    left: -50%;
    animation-name: left;
    animation-duration: 1s;
  }
  @keyframes left {
    0% {
      left: 0%;
    }
    100% {
      left: -50%;
    }
  }
  @keyframes right {
    0% {
      left: -50%;
    }
    100% {
      left: 0%;
    }
  }
  .drawerMenu {
    padding-top: 32px !important;
    background-color: #fff;
    position: relative;
    width: 100%;
    .icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    .drawerMenuItem {
      line-height: 40px;
      font-size: 18px;
    }
    .activeMenu {
      color: #6c93fc;
      font-weight: 700;
      background-size: 60%;
    }
    a {
      display: block;
      width: 100%;
    }
  }
}
</style>
