<template>
  <div class="header-top">
    
    <div class="header-banner">
      <a-carousel
        effect="fade"
        autoplay
      >
        <div class="header-banner-slide header-banner-slide1">
          <img
            src="~@/assets/banner1.png"
            alt=""
          >
        </div>
        <!-- <div class="header-banner-slide header-banner-slide2">
          <img
            src="@/assets/banner2.png"
            alt=""
          >
        </div> -->
      </a-carousel>
      <div class="tag">中国电信内蒙古信息园区</div>
    </div>
    <div class="bannerInfor">
      <ul class="bannerInforUl">
        <li>
          <p>
            <span class="en4">1</span>
            <span class="unit">个</span>
          </p>
          <p class="des">亚太最大互联网数据中心</p>
        </li>
        <li>
          <p>
            <span class="en4">30.6</span>
            <span class="unit">%</span>
          </p>
          <p class="des">中国IDC行业市场份额第一</p>
        </li>
        <li>
          <p>
            <span class="en4">10W</span>
            <span class="unit">+</span>
          </p>
          <p class="des">机柜总量</p>
        </li>
        <li>
          <p>
            <span class="en4">30T</span>
            <span class="unit">+</span>
          </p>
          <p class="des">资源池带宽出口</p>
        </li>
        <li>
          <p>
            <span class="en4">三级等保</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import { webNum } from "@/api/index"
export default {
  name: "bannerBox",
  data() {
    return {
      webNumList: [],
    };
  },
  created() {
    // this.getWebNum();
  },
  methods: {
    /*getWebNum(){
                webNum({key:'one'}).then(res=>{
                    this.webNumList = res.result;
                })
            }*/
  },
};
</script>

<style scoped lang="scss">
.header-top {
  position: relative;
  z-index: 9;
  .bannerInfor {
    position: absolute;
    top: 80vh;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: center;
    ul {
      display: flex;
      border-radius: 3px;
    }
    li {
      background-image: linear-gradient(to right,#5082ff,#4176ff);
      flex: 0 0 auto;
      width: 225px;
      padding: 32px 0 32px 20px;
      text-align: left;
      margin: 0 2px;
      border-radius: 3px;
      p {
        margin: 0;
      }
      .en4 {
        font-size: 42px;
        line-height: 71px;
        color: #fff;
        font-weight: 700;
      }
      .unit {
        font-size: 16px;
        line-height: 32px;
        color: #cde0ff;
      }
      .des {
        font-size: 16px;
        line-height: 32px;
        color: #f2faff;
      }
      .subtitle {
        font-size: 36px;
        line-height: 71px;
        color: #fff;
        font-weight: 700;
      }
    }
    li:first-child {
      border: none;
    }
  }
  .header-banner {
    z-index: 9;
    overflow: hidden;
    .header-banner-slide {
      width: 100%;
      background: #fff;
      .slick-list {
        width: 100% !important;
      }
      img {
        width: 100%;
        visibility: hidden;
      }
    }
    .header-banner-slide1{
      background: url("~@/assets/banner1.png") 100%/100% no-repeat;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .tag{
    bottom: 40% !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    .bannerInfor {
      top: 75vh;
      ul.bannerInforUl {
        margin-top: 0;
        li {
          width: 225px;
          padding: 28px 0 28px 28px;
          .des {
            line-height: 1;
            margin-top: 10px;
          }

          .en4 {
            font-size: 36px;
            line-height: 40px;
          }
        }

        li:nth-child(5) {
          .en4 {
            font-size: 36px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .tag{
    bottom: 40% !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    .bannerInfor {
      top: 70vh;
      ul.bannerInforUl {
        margin-top: -10vh;
        li {
          width: 175px;
          .des {
            line-height: 1;
            margin-top: 10px;
          }

          .en4 {
            line-height: 50px;
          }
        }

        li:nth-child(5) {
          .en4 {
            font-size: 36px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .tag{
    bottom: 40% !important;
    font-size: 24px !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    .bannerInfor {
      top: 70vh;
      ul.bannerInforUl {
        margin-top: -25vh;
        li {
          width: 145px;
          padding: 20px 0 20px 20px;
          .des {
            line-height: 1;
            margin-top: 10px;
          }

          .en4 {
            font-size: 36px;
            line-height: 40px;
          }
        }

        li:nth-child(5) {
          .en4 {
            font-size: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 670px) and (max-width: 768px) {
  .tag{
    bottom: 40% !important;
    font-size: 24px !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    .bannerInfor {
      ul.bannerInforUl {
        margin-top: -30vh;
        li {
          width: 125px;
          padding: 10px 0 10px 10px;
          .des {
            line-height: 1;
            margin-top: 10px;
            font-size: 14px;
          }

          .en4 {
            font-size: 30px;
            line-height: 40px;
          }
        }

        li:nth-child(5) {
          .en4 {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 670px) {
  .tag{
    bottom: 38% !important;
    font-size: 24px !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    .bannerInfor {
      ul.bannerInforUl {
        margin-top: -35vh;
        li {
          width: 104px;
          padding: 10px 0 10px 10px;
          .des {
            line-height: 1;
            margin-top: 10px;
            font-size: 14px;
          }

          .en4 {
            font-size: 30px;
            line-height: 40px;
          }
        }

        li:nth-child(5) {
          .en4 {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tag{
    bottom: 62% !important;
    font-size: 18px !important;
  }
  .box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-top {
    position: relative;
    z-index: 9;
    .bannerInfor {
      position: unset;
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
      text-align: center;
      padding: 0 10px;
      ul.bannerInforUl {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0 0;
        li {
          width: 50%;
          padding: 10px 0 10px 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          margin: 0px !important;

          .des {
            line-height: 1;
            margin-top: 10px;
          }

          .en4 {
            font-size: 22px;
            line-height: 40px;
          }
        }

        li:nth-child(5) {
          width: 100%;
          text-align: center;
          .en4 {
            font-size: 22px;
          }
        }
      }
    }
  }
}
.tag{
  position: absolute;
  bottom: 46%;
  left: 10%;
  background-color: #5082ff;
  padding:10px 16px;
  color: #fff;
  border-radius: 5px;
  font-size: 38px ;

}
</style>
