<template>
  <div>
    <menu-box></menu-box>

    <keep-alive include="[homePage,newsPage]" >
      <router-view mode="hash"  />
    </keep-alive>
  </div>
</template>

<script>
import menuBox from "@/components/menuBox.vue";
export default {
  components: {
    menuBox,
  },
};
</script>

<style>
</style>