<template>
  <div class="advantage">
    <a-row>
      <a-col
        :xxl="{offset:4,span:16}"
        :xl="{offset:3,span:18}"
        :lg="{offset:2,span:20}"
        :md="{offset:1,span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="titleWarp">
          <!--                    <img src="@/assets/title-icon09.png" alt="">-->
          <div class="en">ADVANTAGE</div>
          <div class="cn">中国电信内蒙古信息园区六大优势</div>
        </div>
      </a-col>
    </a-row>
    <a-row class="wrap">
      <a-col
        :xxl="{offset:2,span:10}"
        :xl="{offset:2,span:10}"
        :lg="{offset:2,span:10}"
        :md="{offset:1,span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex">
          <div class="block">
            政策优势
          </div>
          <div class="context">内蒙古是全国唯一同时担负大数据基础设施统筹发展类综合试验区和一体化算力网络国家枢纽节点建设重任的地区，呼和浩特是内蒙古谋划布局数字经济的核心区域，正式纳入了国家“东数西算”战略布局。另一方面，内蒙古将发布支持和林格尔数据中心集群建设“1+2+N”政策包，从能耗、资金、管理、电力等方面举全区之力予以支持。</div>
        </div>
      </a-col>
      <a-col
        :xxl="{span:10}"
        :xl="{span:10}"
        :lg="{span:10}"
        :md="{span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex">
          <div class="block">
            算力优势
          </div>
          <div class="context">呼和浩特先后引入了移动、联通、电信“三大”运营商，中行、农行、建行、交行、内蒙古农信社“五大”金融机构，华为、东方国信、中数兴盛“三大”头部企业数据中心，布局建设数据、运营、研发中心;建成“青城之光”、内蒙古超级大脑等4家超算应用平台，阿里、并行等6家智算超算中心在建。目前，数据中心标准机架达到15万架，服务器装机能力达到100万台，超算能力达到120P。</div>
        </div>
      </a-col>
      <a-col
        :xxl="{offset:2,span:10}"
        :xl="{offset:2,span:10}"
        :lg="{offset:2,span:10}"
        :md="{offset:1,span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex right">
          <div class="block">
            区位优势
          </div>
          <div class="context">呼和浩特是自治区首府，地处京津冀环渤海经济圈腹地，是国家向北开放、连接中蒙俄经济走廊的重要枢纽城市，也是国际公认的大数据产业黄金纬度区中距离北京最近的省会城市。</div>
        </div>
      </a-col>
      <a-col
        :xxl="{span:10}"
        :xl="{span:10}"
        :lg="{span:10}"
        :md="{span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex right">
          <div class="block">
            气候优势
          </div>
          <div class="context">呼和浩特位于北纬40-42度之间，年平均气温7.7°C左右，空气干燥、气候凉爽，天然降温就能保证大数据中心自然散热，节约成本可达45%以上，是建设大数据中心的“理想之地”。</div>
        </div>
      </a-col>
      <a-col
        :xxl="{offset:2,span:10}"
        :xl="{offset:2,span:10}"
        :lg="{offset:2,span:10}"
        :md="{offset:1,span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex">
          <div class="block">
            网络优势
          </div>
          <div class="context">呼和浩特国家级互联网骨干直联点、国际互联网数据专用通道开通运行，网络总出口带宽达51.8T，与全国18个直辖市和省会城市建立直达链路，形成了内通全国、外联俄蒙欧的通道网络体系。</div>
        </div>
      </a-col>
      <a-col
        :xxl="{span:10}"
        :xl="{span:10}"
        :lg="{span:10}"
        :md="{span:22}"
        :sm="24"
        :xs="24"
      >
        <div class="flex">
          <div class="block">
            能源优势
          </div>
          <div class="context">内蒙古是全国最主要的可再生能源和能源安全保障基地，拥有全国57%的风能资源，超过21%的太阳能资源，新能源资源丰富。2022年，内蒙古专门出台了《关于调整战略性新兴产业电力交易的若干政策》，数据中心用电成本全国最低。</div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.advantage {
  background: url(~@/assets/advantage-bg.png);
  background-repeat: no-repeat;
  .wrap {
    padding: 100px 0;
    padding-bottom: 200px;
  }
  .right {
    margin: 100px 0;
    justify-content: flex-end;
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: linear-gradient(45deg, #147cdd, #4aa3f3);
    border-radius: 15px;
    box-shadow: 15px 15px 30px rgb(175, 216, 255),
      inset -2px -2px 3px 4px #2977db;
    font-size: 34px;
    color: #fff;
    text-align: center;
    word-spacing: 10px;
    line-height: 38px;
    letter-spacing: 5px;
  }
  .context {
    width: calc(65% - 100px);
    margin-left: 3%;
    background-color: rgba(#fff, 0.5);
    padding: 18px;
    line-height: 26px;
    letter-spacing: 4px;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 25px 20px 60px rgb(175, 216, 255),
      inset -2px -2px 3px 4px rgba($color: #d7e8f8, $alpha: 0.4);
    margin-right: 7%;
  }
}
@media screen and (max-width: 1600px) {
  .context {
    width: calc(80% - 100px) !important;
  }
}
@media screen and (max-width: 1200px) {
  .block{
     width: 80px !important;
    height: 80px !important;
     font-size: 24px !important;
  }
  .context {
    width: calc(90% - 100px) !important;
  }
}
@media screen and (max-width: 992px) {
  .flex{
    margin: 20px 0 !important;
  }
  .block{
     width: 80px !important;
     height: 80px !important;
     font-size: 24px !important;
  }
  .context {
    width: calc(90% - 100px) !important;
  }
}
</style>