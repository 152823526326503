<template>
    <div class="attestation">
        <a-row>
            <a-col
                    :xxl="{offset:4,span:16}"
                    :xl="{offset:3,span:18}"
                    :lg="{offset:2,span:20}"
                    :md="{offset:1,span:22}"
                    :sm="24"
                    :xs="24"
            >
                <div class="titleWarp">
                    <!--                    <img src="@/assets/title-icon09.png" alt="">-->
                    <div class="en">ATTESTATION</div>
                    <div class="cn">多项行业最高标准运营认证</div>
                </div>
            </a-col>
        </a-row>
        <a-row class="attestation-box">
            <a-col
                    :xxl="{offset:4,span:16}"
                    :xl="{offset:3,span:18}"
                    :lg="{offset:2,span:20}"
                    :md="{offset:1,span:22}"
                    :sm="{span:24,offset:0}"
                    :xs="{span:24,offset:0}">
                <div class="list">
                    <div class="item"
                         v-for="(item,index) in attestationList"
                         :key="index"
                         :class="idx==index?'itemBg':''"
                         @click="clickItem(index)"
                    >
                        <div class="itemBgRadius">
                            <div class="images">
                                <img :src="item.icon" alt="">
                            </div>
                            <div>{{item.title}}</div>
                            <div>{{item.en}}</div>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                idx: 0,
                attestationList: [
                    {
                        icon: require("@/assets/attestation-icon1.png"),
                        title: "国际标准化组织",
                        en: "ISO 9001",
                    },
                    {
                        icon: require("@/assets/attestation-icon2.png"),
                        title: "Uptime Institute",
                        en: "M&O运营认证",
                    },
                    {
                        icon: require("@/assets/attestation-icon3.png"),
                        title: "国际标准化组织",
                        en: "ISO 14001",
                    },
                    {
                        icon: require("@/assets/attestation-icon4.png"),
                        title: "工信部",
                        en: "A级机房标准",
                    },
                    {
                        icon: require("@/assets/attestation-icon5.png"),
                        title: "公安部",
                        en: "等保III级",
                    },
                    {
                        icon: require("@/assets/attestation-icon6.png"),
                        title: "中国电信",
                        en: "五星级机房标准",
                    },
                ],
            };
        },
        methods: {
            clickItem(index) {
                this.idx = index
            }
        }
    };
</script>

<style lang="scss" scoped>
    .attestation {
        padding-top: 30px;
        padding-bottom: 40px;
        background: url(@/assets/yyrz-bg.png) no-repeat center;
        background-size: 100% 100%;

        .attestation-box {
            /*background-image: url("~@/assets/attestation-bg1.png");*/
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding-top: 50px;
            .itemBg .itemBgRadius {
                background: url("@/assets/yyrz-radius.png") no-repeat center;
                background-size: auto 100%;
            }
            .item {
                padding-top: 10px;
                padding-bottom: 20px;
                width: 33%;
                text-align: center;
                .itemBgRadius {
                    padding: 40px 0;
                }
                .images {
                    margin: 0 auto;
                    width: 200px;
                    height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    img {
                        width: 180px;
                        height: 180px;
                        border-radius: 50%;
                        margin-bottom: 20px;
                        object-fit: contain;
                    }
                }
                div {
                    padding-top: 5px;
                    color: $primary-color;
                    font-size: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .attestation {
            .list {
                padding-top: 30px;

                .item {
                    .images {
                        width: 160px;
                        height: 160px;

                        img {
                            width: 140px;
                            height: 140px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .attestation {
        }
    }

    @media screen and (max-width: 576px) {
        .attestation {
            padding: 0 15px;
            .titleWarp[data-v-2128c16b] {
                text-align: left;
                margin: 20px 0 0px;
                width: auto;
                display: inline-block;
            }
            .list {
                padding-top: 10px;

                .item {
                    padding: 0;
                    .itemBgRadius{
                        padding: 25px 0;
                    }
                    .images {
                        width: 100px;
                        height: 100px;

                        img {
                            width: 80px;
                            height: 80px;
                        }
                    }

                    div {
                        padding-top: 10px;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
</style>
