<template>
  <div
    class="news"
    @click="close"
  >
    <menuBox></menuBox>
    <div class="top">
      <h1 class="en-title">NEWS CENTER</h1>
      <h2 class="title">新闻中心</h2>
      <p class="lettle-title">我们为您传递更多的企业信息</p>
      <p class="lettle-title">使您更加全方位的了解您所关注行业内容</p>
    </div>
    <div class="new-title">
      <div class="cn">{{typeList.length>0?typeList[queryParams.newType-1].title : ''}}</div>
      <div class="en">{{typeList.length>0?typeList[queryParams.newType-1].description : ''}}</div>
    </div>
    <div class="banner-box">
      <a-row>
        <a-col
          :xxl='17'
          :xl='17'
          :lg='24'
          :md='24'
          :xs='24'
        >
          <a-carousel
            dots
            dotsClass="dots"
            autoplay
          >
            <div
              class="banner"
              v-for="(item,i) in bannerList"
              :key="i"
            >
              <div class="flex">
                <div class="banner-img">
                  <img
                    :src="paresImg(item.newPhoto.split(',')[0])"
                    alt=""
                  >
                </div>
                <div class="banner-text">
                  <p class="banner-titl">{{item.newTitle}}</p>
                  <p
                    class="banner-content"
                    v-html="item.newContent"
                  ></p>
                  <div class="bottom">
                    <span>{{moment(item.sendTime).format('YYYY-MM-DD')}}</span>
                    <span
                      class="cur"
                      @click="toInfo(item.id)"
                    >详细信息></span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="banner">
              <div class="flex">
                <div class="banner-img">
                  <img
                    src="~@/assets/new-bg.png"
                    alt=""
                  >
                </div>
                <div class="banner-text">
                  <p class="banner-titl">中国电信云计算内蒙古信息园十年发展成果暨新一代算力中心发布会成功举办</p>
                  <p class="banner-content">5月28日，中国电信(6.170.0.05.0.82%)云计算内蒙古言息园十年成果暨新一代算力中心发布会在呼和浩特举办</p>
                  <div class="bottom">
                    <span>2023-05-30</span>
                    <span class="cur">详细信息></span>
                  </div>
                </div>
              </div>
            </div> -->
          </a-carousel>
        </a-col>
        <a-col
          :xxl='7'
          :xl='7'
          :lg='24'
          :md='24'
          :xs='24'
        >
          <div class="search-box">
            <div class="year">年份</div>
            <div class="calendar">
              <div>
                <div
                  class="select flex"
                  @click.stop="toggles"
                >
                  <span>{{selyear}}年</span>
                  <span class="arrow">▲</span>
                </div>
                <div
                  class="toggle"
                  id="toggles"
                  style="display:none"
                >
                  <div
                    class="years"
                    :class="year==selyear?'active-years':''"
                    v-for="year in yearList"
                    :key="year"
                    @click="selectYear(year)"
                  >{{year}}年</div>
                </div>
              </div>
              <div class="calendars flex">
                <div
                  class="mouth"
                  v-for="m in monthList"
                  :class="m  == selmonth ? 'active-mouth':''"
                  @click="selectMonth(m)"
                  :key="m"
                >{{m}}月</div>
              </div>
            </div>
            <div class="search flex">
              <a-input
                placeholder="关键词"
                class="input"
                allow-clear
                v-model="queryParams.newTitle"
              ></a-input>
              <a-button
                class="button"
                @click="getList"
              >搜索</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-row>
        <a-col
          :xxl='17'
          :xl='17'
          :lg='24'
          :md='24'
          :xs='24'
        >
          <div class="tab flex">
            <div
              :class="index +1 ==queryParams.newType?'active':''"
              v-for="(item,index) in typeList"
              :key="index"
              @click="handleTab(item)"
            >{{item.title}}</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :xxl='17'
          :xl='17'
          :lg='24'
          :md='24'
          :xs='24'
        >
          <div class="list">
            <div
              class="item flex"
              v-for="item in newsList"
              :key="item.id"
            >
              <div class="left flex">
                <div class="large">{{moment(item.sendTime).format('DD/')}}</div>
                <div>
                  <div class="small">{{moment(item.sendTime).format('MM月')}}</div>
                  <div class="small">{{moment(item.sendTime).format('YYYY')}}</div>
                </div>
              </div>
              <div class="right">
                <div class="content-title">
                  {{item.newTitle}}
                </div>
                <div
                  class="content"
                  v-html="item.newContent"
                >
                </div>
                <div
                  class="info"
                  @click="toInfo(item.id)"
                >
                  查看详情
                </div>
              </div>
            </div>
            <div
              class="bottom"
              v-if="newsList.length>0"
            >
              <div
                class="button"
                @click="hrefTo"
              >更多新闻资讯 →</div>
            </div>
            <div
              v-else
              class="noList"
            >
              <img
                src="@/assets/noList.png"
                alt=""
              >
              <div>暂无数据</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
import menuBox from "@/components/menuBox.vue";
import moment from "moment";
import { bannerList, newsList, dictType, windowLink } from "@/api/index.js";
export default {
  name:'newsPage',
  components: {
    menuBox,
  },
  data() {
    return {
      yearList: [],
      monthList: [],
      bannerList: [],
      newsList: [],
      typeList: [],
      queryParams: {
        pageSzie: 10,
        pageNo: 1,
        newType: 1,
        sendTime: "",
      },
      selyear: moment().format("YYYY"),
      selmonth: moment().format("MM"),
    };
  },
  created() {
    // this.$nextTick(() => {
      this.queryParams.sendTime = moment(
        this.selyear + "-" + this.selmonth
      ).format("YYYY-MM-DD HH:mm:SS");

      this.parseYear();
      this.parseMonth();
      this.getList();
      this.getBannerList();
      this.getDict();
    // });
  },
  methods: {
    moment,
    getDict() {
      dictType("news_type", null).then((res) => {
        if (res.success) {
          this.typeList = res.result;
        } else {
          this.typeList = [];
        }
      });
    },
    getList() {
      newsList({
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSzie,
        sendTime: this.queryParams.sendTime,
        newTitle:
          this.queryParams.newTitle && this.queryParams.newTitle.trim() != ""
            ? `*${this.queryParams.newTitle.trim()}*`
            : "",
        newType: this.queryParams.newType,
      }).then((res) => {
        if (res.success) {
          this.newsList = res.result.records;
        } else {
          this.newsList = [];
        }
      });
    },
    toInfo(id) {
      this.$router.push({
        path: "info",
        query: {
          id,
        },
      });
    },
    getBannerList() {
      bannerList().then((res) => {
        if (res.success) {
          this.bannerList = res.result;
        } else {
          this.bannerList = [];
        }
      });
    },
    paresImg(url) {
      return windowLink + url;
    },
    close() {
      document.getElementById("toggles").style.display = "none";
    },
    handleTab(item) {
      this.queryParams.newType = item.value;
      this.getList();
    },
    hrefTo() {
      let obj = this.typeList[this.queryParams.newType - 1];
      this.$router.push({
        path: "list",
        query: {
          newType: window.btoa(this.queryParams.newType),
          title: obj.title,
          description: obj.description,
        },
      });
    },
    toggles() {
      const div = document.getElementById("toggles");
      console.log(div.style.display);
      if (div.style.display === "none") {
        div.style.display = "flex";
      } else {
        div.style.display = "none";
      }
    },
    selectYear(year) {
      this.selyear = year;
      this.queryParams.sendTime = moment(year + "-" + this.selmonth).format(
        "YYYY-MM-DD HH:mm:SS"
      );

      this.getList();
    },
    selectMonth(month) {
      this.selmonth = month;
      this.queryParams.sendTime = moment(this.selyear + "-" + month).format(
        "YYYY-MM-DD HH:mm:SS"
      );
      this.getList();
    },
    parseYear() {
      let startYear = this.selyear - 4;
      let endYear = this.selyear + 16;
      for (let i = 0; i < endYear - startYear; i++) {
        this.yearList.push(startYear + i);
      }
    },
    parseMonth() {
      let endMonth = 12;
      for (let i = 1; i <= endMonth; i++) {
        if (i < 10) {
          this.monthList.push("0" + i); //month is zero based.
        } else {
          this.monthList.push(i); //month is zero based.
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dots {
  height: 10px !important;
  width: 45% !important;
  bottom: 20px !important;
  button {
    width: 10px !important;
    height: 10px !important;
    background: rgba($color: #000000, $alpha: 0.65) !important;
    border-radius: 50% !important;
  }
  .slick-active {
    button {
      background: #db5f5e !important;
    }
  }
}
.news {
  .top {
    padding: 64px;
    background: url("@/assets/new-bg.jpg") no-repeat;

    background-position: 70%;
    background-size: cover;
    height: 500px;
    .en-title {
      margin: 0;
      font-size: 48px;
      letter-spacing: 3px;
      padding-top: calc(8% - 64px);
      font-weight: bold;
      color: #0067fe;
    }
    .title {
      color: #0067fe;
      letter-spacing: 3px;
      font-weight: bold;
      font-size: 40px;
    }
    .lettle-title {
      color: #0067fe;
      letter-spacing: 2px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .new-title {
    color: #0067fe;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 20px;
    border-bottom: 2px solid #eee;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      width: 40px;
      height: 4px;
      left: calc(50% - 20px);
      background-color: #0067fe;
      display: block;
    }
    .cn {
      font-size: 42px;
    }
    .en {
      font-size: 30px;
      letter-spacing: 3px;
    }
  }
  .banner-box {
    padding: 48px;
  }
  .banner {
    background-color: #eee;
    padding: 16px;
    position: relative;
    .flex {
      background-color: #fff;
    }
    .banner-img {
      width: 45%;
      img {
        height: 350px;
        width: 100%;
        object-fit: cover;
      }
    }
    .banner-text {
      width: 55%;
    }
    .banner-titl {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .banner-content {
      padding-top: 0;
      font-size: 22px;
      overflow: hidden;
      height: 250px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .bottom {
      position: absolute;
      width: calc(55% - 16px);
      display: flex;
      justify-content: space-between;
      bottom: 16px;
      right: 16px;
      color: #0067fe;
      font-size: 20px;
      .cur {
        cursor: pointer;
      }
    }
    div {
      padding: 8px;
      background-color: #fff;
    }
  }
  .search-box {
    position: relative;
    display: table;
    box-sizing: border-box;
    padding: 16px 32px;
    margin: 0 auto;
    min-width: 280px;
    .year {
      font-size: 22px;
      color: #0067fe;
      padding-bottom: 8px;
    }
    .calendar {
      background-color: #eee;
      .select {
        cursor: pointer;
        background-color: #0067fe;
        padding: 8px 16px;
        font-size: 18px;
        color: #fff;
        justify-content: space-between;
        .arrow {
          display: inline-block;
          transform: rotate(180deg);
          transition: all 1s;
        }
      }
      .calendars {
        flex-wrap: wrap;
        font-size: 18px;
        .mouth {
          width: 25%;
          text-align: center;
          height: 60px;
          line-height: 60px;
          cursor: pointer;
        }
        .active-mouth {
          position: relative;
          z-index: 1;
          color: #fff;
          &::before {
            content: "";
            z-index: -1;
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            background-color: #0067fe;
            border-radius: 50%;
          }
        }
      }
    }
    .toggle {
      flex-wrap: wrap;
      font-size: 18px;
      position: absolute;
      width: calc(100% - 64px);
      z-index: 99;
      background-color: #eee;
      .years {
        width: 25%;
        text-align: center;
        height: 60px;
        line-height: 60px;
        position: relative;
        cursor: pointer;
      }
      .active-years {
        color: #fff;
        &::before {
          content: "";
          z-index: -1;
          display: block;
          width: 80px;
          height: 40px;
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 40px);
          background-color: #0067fe;
        }
      }
    }
    .search {
      background-color: #eee;
      margin: 16px 0;
      padding: 16px;

      .input {
        height: 40px;
        margin-right: 16px;
        background-color: #eee;
        input {
          height: 40px;
        }
      }
      .button {
        height: 30px;
        background-color: #0067fe;
        color: #fff;
      }
    }
  }
  .tab {
    border-bottom: 3px solid #0067fe;
    padding-left: 60px;
    div {
      cursor: pointer;
      padding: 16px 48px;
      font-size: 20px;
      color: #0067fe;
    }
    .active {
      background-color: #0067fe;
      color: #fff;
    }
  }
  .item {
    border-bottom: 1px solid #0067fe;
    .left {
      margin: 16px 0;
      padding: 32px 0;
      flex: 200px 0 0;
      justify-content: center;
      align-items: center;
      color: #0067fe;
      border-right: 2px solid #0067fe;
      .large {
        font-size: 40px;
      }
      .small {
        font-size: 16px;
        padding-left: 5px;
        line-height: 20px;
      }
    }
    .right {
      position: relative;
      padding-left: 32px;
      padding-top: 16px;
      .content-title {
        font-size: 18px;
        color: #0067fe;
        font-weight: 600;
      }
      .content {
        font-size: 16px;
        padding: 8px 0;
        overflow: hidden;
        height: 80px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .info {
        bottom: 16px;
        word-wrap: break-word;
        cursor: pointer;
        color: #0067fe;
      }
    }
  }
  .bottom {
    padding: 32px;
    .button {
      margin: 0 auto;
      padding: 8px 16px;
      border-radius: 5px;
      color: #0067fe;
      width: 160px;
      border: 2px solid #0067fe;
      text-align: center;
      cursor: pointer;
    }
  }
  .noList {
    padding: 60px;
    text-align: center;
    img {
      width: 150px;
    }
    div {
      font-size: 18px;
      padding-top: 20px;
      color: #999;
    }
  }
}
@media screen and (max-width: 1000px){
  .top {
    padding: 32px !important;
    height: 500px !important;
    .en-title{
      font-size: 40px !important;
    };
    .title{
      font-size: 32px !important;
    }
  }
  .new-title{
    .cn{
      font-size: 36px !important;
    }
    .en{
      font-size: 18px !important;
    }
  }
  .banner-box{
    padding: 16px !important;
    .banner-img {
      img{
        height: 250px !important;
      }
    }
    .banner-titl{
      font-size: 20px !important;
    }
    .banner-content{
      height: 150px !important;
    }
    .bottom{
      font-size: 14px !important;
    }
  }
} 
@media screen and (max-width: 650px) {
  .item{
    .left {
      flex: 120px 0 0 !important;
      .large{
        font-size: 32px !important;
      }
    }
  }
  .top {
    padding: 32px !important;
    height: auto !important;
    .en-title{
      font-size: 40px !important;
    };
    .title{
      font-size: 32px !important;
    }
  }
  
  .new-title{
    .cn{
      font-size: 28px !important;
    }
    .en{
      font-size: 18px !important;
    }
  }
  .banner-box{
    padding: 16px !important;
    .banner-img {
      img{
        height: 150px !important;
      }
    }
    .banner-titl{
      font-size: 20px !important;
    }
    .banner-content{
      height: 100px !important;
    }
    .bottom{
      font-size: 14px !important;
    }
  }
  .tab{
    padding: 0 !important;
    margin: 0 auto !important;
    div{
      padding: 16px !important;
    }
  }
}
@media screen and (max-width: 450px){
  .tab{
    padding: 0 !important;
    margin: 0 auto !important;
    div{
      padding: 8px !important;
    }
  }
} 
</style>