// 导入封装好的网络请求类工具
import api from './api';
// export const windowLink = windowConfig.baseUrl;
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
// export const login = (params) => api.getNoToken('/oc/ocCarFiles/login', params);

// 首页接口
export const windowLink = windowConfig.baseUrl+'/sys/common/static/';
export const liuyanAdd = (params) => api.post('web/api/add',params);
export const webNum = (params) => api.get('web/api/queryByKey',params);

export const dictType = (dictType,params) => api.get('/sys/dict/getDictItems/'+dictType,params);

export const bannerList= (params) => api.get('/webNews/api/listByTop',params)
export const newsList = (params) => api.get('/webNews/api/listByType',params)
export const newsInfo = (params) => api.get('/webNews/api/queryById',params)
export const addVisit = (params) => api.post('/web/onlVisit/add',params)
export const onlVisit = (params) => api.get('/web/onlVisit/queryById',params)
/**
export const oilAdd = (params) => api.post('/oc/ocGasUpRecords/add',params);
export const oilEdit = (params) => api.put('/oc/ocGasUpRecords/edit',params);
export const oilDelete = (params) => api.delete('/oc/ocGasUpRecords/delete',params);
export const uploadAction = (params) => api.upload('/sys/common/upload',params);
**/
