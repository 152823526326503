<template>
  <div class="page-index-container">
    <!-- <menuBox></menuBox> -->
    <!-- 联系我们 + 留言板-->
    <contactBox></contactBox>
    <!-- 顶部banner-->
    <div class="box box1"
         id="sy">
      <bannerBox></bannerBox>
    </div>
    <!--    北方核心  VR全景-->
    <div class="box box2"
         id="jccpPage">
      <ywzy-box></ywzy-box>
    </div>
    <!--    中国云谷-->
    <div class="box box3"
         id="zgyg">
      <zgyg-box></zgyg-box>
    </div>
    <!--    为什么选择-->
    <div class="box">
      <!-- <wsxzBox></wsxzBox> -->
    </div>
    <!--    适用场景-->
    <div class="box"
         id="sycjBox">
      <sycjBox></sycjBox>
    </div>
    <!--    信息园区-->
    <div class="box"
         id="dlwhPage">
      <business-box></business-box>
    </div>
    <!--    提供什么服务-->
    <div class="box box5"
         id="tgfwBox">
      <tgfwBox></tgfwBox>
    </div>
    <!--    特色服务-->
    <div class="box box7">
      <featured-box></featured-box>
    </div>
    <!--    多项高标准-->
    <div class="box box8">
      <attestation-box></attestation-box>
    </div>
    <!-- 六大优势 -->
    <div class="box"
         id="ys">
      <advantage-box></advantage-box>
    </div>
    <!--    合作伙伴-->
    <div class="box box9">
      <customer-box :visitNum="visitNum"></customer-box>
    </div>

    <!-- 底部 -->
    <!--<footer-box></footer-box>-->
  </div>
</template>

<script>
import bannerBox from "@/components/bannerBox.vue";
// import menuBox from "@/components/menuBox.vue";
import contactBox from "@/components/contactBox.vue";
// import footerBox from "../components/footerBox.vue";
import ywzyBox from "@/components/ywzyBox.vue";
import zgygBox from "@/components/zgygBox";
import wsxzBox from "@/components/wsxzBox.vue";
import sycjBox from "@/components/sycjBox";
import BusinessBox from "@/components/businessBox";
import tgfwBox from "@/components/tgfwBox.vue";
import customerBox from "@/components/customerBox.vue";
import attestationBox from "@/components/attestationBox.vue";
import featuredBox from "@/components/featuredBox.vue";
import advantageBox from "@/components/advantageBox.vue";
import { onlVisit, addVisit } from "@/api/index.js";

export default {
  name: "homePage",
  props: {},
  components: {
    bannerBox,
    contactBox,
    // footerBox,
    ywzyBox,
    zgygBox,
    // wsxzBox,
    sycjBox,
    BusinessBox,
    tgfwBox,
    customerBox,
    attestationBox,
    // menuBox,
    featuredBox,
    advantageBox,
  },
  data () {
    return {
      visitNum: 0
    };
  },

  created () {
    this.addVisit()
    this.getVisitNum()
  },
  mounted () {
    this.$nextTick(() => {
      this.hrefMD();
    });
  },
  methods: {
    addVisit () {
      addVisit().then(res => {
        console.log(res);
      })
    },
    getVisitNum () {
      onlVisit().then(res => {
        this.$nextTick(() => {
          this.visitNum = Number(res.result)
        })
      })
    },
    hrefMD () {
      let menu = JSON.parse(sessionStorage.getItem("menu"));
      console.log(menu);
      document.querySelector(`#${menu.name}`).scrollIntoView(true);
    },
  },
};
</script>
