import Vue from "vue";
import main from '@/views/mainPage.vue'
import VueRouter from "vue-router";
import homePage from "../views/homePage"
import newsPage from "../views/newsPage"
import newsListPage from "../views/newsList"
import newsInfoPage from "../views/newsDetail"


Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: "/",
    name: "main",
    meta: {
      title: '云计算信息园区',
      auth: false,// 是否需要登录
    },
    component: main,
    children: [
      {
        path: "/",
        name: "homePage",
        meta: {
          title: '云计算信息园区',
          auth: false,// 是否需要登录
        },
        component: homePage,
      }, {
        path: "/news",
        name: "news",
        meta: {
          title: '云计算信息园区',
          auth: false,// 是否需要登录
        },
        component: newsPage,
      }, {
        path: "/list",
        name: "list",
        meta: {
          title: '云计算信息园区',
          auth: false,// 是否需要登录
        },
        component: newsListPage,
      }, {
        path: "/info",
        name: "info",
        meta: {
          title: '云计算信息园区',
          auth: false,// 是否需要登录
        },
        component: newsInfoPage,
      },
    ]
  },

  /*,{
    path: "/jccpPage",
    name: "jccpPage",
    meta: {
      title: '云计算信息园区',
      auth:false,// 是否需要登录
    },
    component: jccpPage,
  },{
    path: "/dlwhPage",
    name: "dlwhPage",
    meta: {
      title: '云计算信息园区',
      auth:false,// 是否需要登录
    },
    component: dlwhPage,
  },{
    path: "/xtjcPage",
    name: "xtjcPage",
    meta: {
      title: '云计算信息园区',
      auth:false,// 是否需要登录
    },
    component: xtjcPage,
  },{
    path: "/zzywPage",
    name: "zzywPage",
    meta: {
      title: '云计算信息园区',
      auth:false,// 是否需要登录
    },
    component: zzywPage,
  }*/
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    // 返回 y = 0，代表滚动条在最上方
    return {
      y: 0 // 最小是 0
    }
  }

});

export default router;
